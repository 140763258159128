import { Flex, Image, Text, useToast } from "@chakra-ui/react";
import MultiSelectWithCheckbox, { Option } from "./MultiSelectWithCheckbox";
import { useEffect, useRef, useState } from "react";
import SingleSelect from "./SingleSelect";
import { ICertificate } from "../_services/interface/certificate.interface";
import { formatCnpj } from "../pages/dashboard/Companies/Company/util/cnpj";
import { ICollaborators, IGetEmployeesResponse } from "../_services/interface/company.interface";
import { IFilterListCompanyGroup } from "../_services/interface/companyGroup.interface";
import { listCompanyGroup } from "../_services/companyGroup.service";
import { ICardList } from "../pages/dashboard/TaxKanban/interfaces/ICardList";
import { getAllFiles, getKanban } from "../pages/dashboard/TaxKanban/_services/kanban.service";
import { getClientEmployees } from "../_services/client.service";
import moment from "moment";
import { listCnds } from "../_services/cnd.service";
import { ICNDListData, IFilterListCertificate } from "../_services/interface/cnd.interface";
import { IFiles } from "../pages/dashboard/TaxKanban/interfaces/IFiles";
import { IFilterListPersona } from "../_services/interface/persona.interface";
import { formatCpf } from "../pages/dashboard/Persona/utils/cpf";
import { DateFilter } from "./SelectFilter";
import { listUsers } from "../_services/user.service";
import { IFilterListUser, IUser } from "../_services/interface/user.interface";
import { listSchedule } from "../_services/irs.service";
import { getCompanyFilterOptions, getTaxCurrentAccountFilterOptions, getFilterCardName, getPersonaFilterOptions } from "../_services/filter.service";
import { ICompanyFilter, ITaxCurrentAccountFilter, IPersonaFilter } from "../_services/interface/filter.interface";
import { useClientContext } from "./sidebar";

export enum FilterTypeEnum {
  // Company
  companyFilter = 'company',
  companyGroupFilter = 'companyGroup',
  companyTypeFilter = 'companyType',
  companySituationFilter = 'companySituation',
  companySituationIeFilter = 'companySituationIe',
  companyOrPersonaTypeFilter = 'companyOrPersonaType',
  companyCodeFilter = 'companyCode',
  companyStateFilter = 'companyState',
  companyCityFilter = 'companyCity',
  companyStatusFilter = 'companyStatus',

  // Persona
  personaNameFilter = 'personaName',
  personaDocumentFilter = 'personaDocument',
  
  // Holder
  holderFilter = 'holder',

  // Certificate
  certificateTypeFilter = 'certificateType',
  certificateStatusFilter = 'certificateStatus',
  certificateStatusValidInvalidFilter = 'certificateStatusValidInvalid',
  certificateStateFilter = 'certificateState',
  certificateCityFilter = 'certificateCity',
  certificateValidUntilFilter = 'certificateValidUntil',
  
  // Kanban
  kanbanPeriodFilter = 'kanbanPeriod',
  kanbanScheduleFilter = 'kanbanSchedule',
  kanbanCardNameFilter = 'kanbanCardName',
  kanbanScheduleStatusFilter = 'kanbanScheduleStatus',
  kanbanFileFilter = 'kanbanFile',
  
  // User
  userNameFilter = 'userName',
  userEmailFilter = 'userEmail',
  userStatusFilter = 'userStatus',
  
  // Jurisdiction
  jurisdictionFilter = 'jurisdiction',
  
  // Date
  monthFilter = 'month',
  yearFilter = 'year',
  startCreatedAtFilter = 'startCreatedAt',
  endCreatedAtFilter = 'endCreatedAt',
  startIssuedAtFilter = 'startIssuedAt',
  endIssuedAtFilter = 'endIssuedAt',
  
  // Search
  searchTypeFilter = 'searchType',
  
  // Collaborator
  collaboratorFilter = 'collaborator',

  // Tax Current Account
  taxCurrentAccountStatusFilter = 'taxCurrentAccountStatus',
  taxCurrentAccountTypeFilter = 'taxCurrentAccountType',
  taxCurrentAccountCityFilter = 'taxCurrentAccountCity',
  taxCurrentAccountStateFilter = 'taxCurrentAccountState',
}

type FilterField = {
  name: string;
  position: number;
  size: number;
};

export type FilterRow = {
  rowFields: FilterField[];
  position: number;
};

type FilterDataSelect = {
  id: string;
  label: string;
  options: Option[];
  selected: Option[];
  onChange: (values: Option[]) => void;
  isSingleSelect?: boolean;
}

type FilterDataDate = {
  id: string;
  label: string;
  placeholder: string;
  selected: string[];
  onChange: (values: string) => void;
  isDate: boolean;
}

type FilterDataFields = {
  [key: string]: FilterDataSelect|FilterDataDate;
}

export type FilterDataSelected = {
  [key: string]: string[];
}

interface IFilters {
  filters: FilterRow[];
  selectedValuesHook: React.Dispatch<React.SetStateAction<FilterDataSelected | null>>;
  certificates?: ICertificate[];
  clearFilters?: boolean;
  reinitFilter?: boolean;
  allCompanies?: boolean;
  currentMonthFilled?: boolean;
}

export const Filters = (props: IFilters) => {
  const toast = useToast();
  const toastRef = useRef<string | number | undefined>(undefined);
  const { guid_client } = useClientContext();

  const standardStatusFilter: Option[] = [
    { value: 'true', label: 'Ativo' },
    { value: 'false', label: 'Inativo' },
  ];

  const standardStatusUserFilter: Option[] = [
    { value: 'true', label: 'Ativo' },
    { value: 'false', label: 'Inativo' },
  ];

  const standardStatusCompanyFilter: Option[] = [
    { value: '1', label: 'Ativo' },
    { value: '0', label: 'Inativo' },
  ];

  // Filter
  const [orderedFilters, setOrderedFilters] = useState<FilterRow[]>([]);
  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);


  // Options to select
  const [optionsCompanies, setOptionsCompanies] = useState<Option[]>([]);
  const [optionsHolder, setOptionsHolder] = useState<Option[]>([]);
  const [optionsPersonaName, setOptionsPersonaName] = useState<Option[]>([]);
  const [optionsPersonaDocument, setOptionsPersonaDocument] = useState<Option[]>([]);
  const [optionsGroupCompany, setOptionsGroupCompany] = useState<Option[]>([]);
  const [optionsCompanySituationIe, setOptionsCompanySituationIe] = useState<Option[]>([]);
  const [optionsCompanySituation] = useState<Option[]>([
    {
      label: "ATIVA",
      value: "ATIVA",
    },
    {
      label: "SUSPENSA",
      value: "SUSPENSA",
    },
    {
      label: "INAPTA",
      value: "INAPTA",
    },
    {
      label: "BAIXADA",
      value: "BAIXADA",
    },
    {
      label: "NULA",
      value: "NULA",
    },
  ]);
  const companyTypeValues = [
    {
      label: "Matriz",
      value: 0,
    },
    {
      label: "Filial",
      value: 1,
    },
    {
      label: "Estrangeira",
      value: 2,
    }
  ];
  const [optionsCompanyType, setOptionsCompanyType] = useState<Option[]>([]);
  const [optionsCompanyOrPersonaType] = useState<Option[]>([
    {
      label: "Empresa",
      value: "4",
    },
    {
      label: "Pessoa Física",
      value: "3",
    },
  ] as Option[]);
  const [optionsCompanyCode, setOptionsCompanyCode] = useState<Option[]>([]);

  const [optionsCertificateType, setOptionsCertificateType] = useState<Option[]>([]);
  const [optionsStatus, setOptionsStatus] = useState<Option[]>([]);
  const [optionsCertificateValidUntil] = useState<Option[]>([
    { label: "Vencidas", value: "not-valid" },
    { label: "A vencer em até 15 dias", value: "15-days" },
    { label: "A vencer em 15 ou mais dias", value: "15-days-more" },
    { label: "A vencer em 30 ou mais dias", value: "30-days-more" },
    { label: "A vencer em 45 ou mais dias", value: "45-days-more" },
    { label: "A vencer em 60 ou mais dias", value: "60-days-more" },
    { label: "A vencer em 90 ou mais dias", value: "90-days-more" },
    { label: "A vencer em mais de 120 dias", value: "120-days-more" },
  ]);

  const [optionsCertificateStatusValidInvalid, setOptionsCertificateStatusValidInvalid] = useState<Option[]>([
    { value: 'valido', label: "Válido" },
    { value: 'invalido', label: "Inválido" },
    { value: "has-last-issued", label: "Certidão Anterior Válida" },
    { value: 'error', label: "Erro" },
  ]);

  const [optionsJurisdictions] = useState<Option[]>([
    { value: "federal", label: "Federal" },
    { value: "state", label: "Estadual" },
    { value: "municipal", label: "Municipal" },
  ]);
  const [optionsStates, setOptionsStates] = useState<Option[]>([]);
  const [optionsCities, setOptionsCities] = useState<Option[]>([]);
  const [optionsSearchType] = useState<Option[]>([
    { value: '1', label: 'Manual' },
    { value: '0', label: 'Automático' }
  ]);
  const [optionsCompanyStatus] = useState<Option[]>(standardStatusCompanyFilter);
  const [optionsKanbanPeriod, setOptionsKanbanPeriod] = useState<Option[]>([]);
  const [optionsKanbanSchedule, setOptionsKanbanSchedule] = useState<Option[]>([]);
  const [optionsKanbanCardName, setOptionsKanbanCardName] = useState<Option[]>([]);
  const [optionsMonth] = useState<Option[]>([
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' }
  ]);
  const [optionsYear] = useState<Option[]>(
    [...Array(21)].map((_, index) => {
      const year = new Date().getFullYear() - 10 + index;

      return {
          value: `${year}`,
          label: `${year}`,
      };
    })
  );
  const [optionsCollaborators, setOptionsCollaborators] = useState<Option[]>([]);
  const [optionsKanbanScheduleStatus] = useState<Option[]>(standardStatusFilter);
  const [optionsKanbanFile, setOptionsKanbanFile] = useState<Option[]>([]);

  const [optionsUserName, setOptionsUserName] = useState<Option[]>([]);
  const [optionsUserEmail, setOptionsUserEmail] = useState<Option[]>([]);
  const [optionsUserStatus] = useState<Option[]>(standardStatusUserFilter);

  // Tax Current Account
  const [optionsTaxCurrentAccountStatus, setOptionsTaxCurrentAccountStatus] = useState<Option[]>([]);
  const [optionsTaxCurrentAccountType, setOptionsTaxCurrentAccountType] = useState<Option[]>([]);
  const [optionsTaxCurrentAccountCity, setOptionsTaxCurrentAccountCity] = useState<Option[]>([]);
  const [optionsTaxCurrentAccountState, setOptionsTaxCurrentAccountState] = useState<Option[]>([]);

  // Selected values
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([]);
  const [selectedHolder, setSelectedHolder] = useState<Option[]>([]);
  const [selectedCompanyGroups, setSelectedCompanyGroups] = useState<Option[]>([]);
  const [selectedCompanyTypes, setSelectedCompanyTypes] = useState<Option[]>([]);
  const [selectedCompanySituation, setSelectedCompanySituation] = useState<Option[]>([]);
  const [selectedCompanySituationIe, setSelectedCompanySituationIe] = useState<Option[]>([]);
  const [selectedCompanyOrPersonaTypes, setSelectedCompanyOrPersonaTypes] = useState<Option[]>([]);
  const [selectedCompanyCodes, setSelectedCompanyCodes] = useState<Option[]>([]);

  const [selectedCertificateType, setSelectedCertificateType] = useState<Option[]>([]);
  const [selectedCertificateStatusValidInvalid, setSelectedCertificateStatusValidInvalid] = useState<Option[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<Option[]>([]);
  const [selectedCertificateValidUntil, setSelectedCertificateValidUntil] = useState<Option[]>([]);

  const [selectedJurisdictions, setSelectedJurisdictions] = useState<Option[]>([]);
  const [selectedStates, setSelectedStates] = useState<Option[]>([]);
  const [selectedCities, setSelectedCities] = useState<Option[]>([]);
  const [selectedSearchType, setSelectedSearchType] = useState<Option[]>([]);
  const [selectedCompanyStatus, setSelectedCompanyStatus] = useState<Option[]>([]);
  const [selectedPersonaName, setSelectedPersonaName] = useState<Option[]>([]);
  const [selectedPersonaDocument, setSelectedPersonaDocument] = useState<Option[]>([]);
  const [selectedKanbanPeriods, setSelectedKanbanPeriods] = useState<Option[]>([]);
  const [selectedKanbanSchedule, setSelectedKanbanSchedule] = useState<Option[]>([]);
  const [selectedKanbanCardName, setSelectedKanbanCardName] = useState<Option[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<Option[]>([...optionsMonth.filter(opt => opt.value === moment().format('MM'))]);
  const [selectedYear, setSelectedYear] = useState<Option[]>([...optionsYear.filter(opt => opt.value === moment().format('YYYY'))]);
  const [selectedCollaborators, setSelectedCollaborators] = useState<Option[]>([]);
  const [selectedKanbanScheduleStatus, setSelectedKanbanScheduleStatus] = useState<Option[]>([...optionsKanbanScheduleStatus.filter(opt => opt.value === 'true')]);
  const [selectedKanbanFile, setSelectedKanbanFile] = useState<Option[]>([]);

  const [selectedUserName, setSelectedUserName] = useState<Option[]>([]);
  const [selectedUserEmail, setSelectedUserEmail] = useState<Option[]>([]);
  const [selectedUserStatus, setSelectedUserStatus] = useState<Option[]>([{ value: 'true', label: 'Ativo' }]);

  // Data filters
  const [selectedStartCreatedAtFilter, setStartCreatedAtFilter] = useState<string[]>(props.currentMonthFilled ? [moment.utc().month(moment.utc().month()).startOf('month').format('YYYY-MM-DD')] : []);
  const [selectedEndCreatedAtFilter, setEndCreatedAtFilter] = useState<string[]>(props.currentMonthFilled ? [moment.utc().month(moment.utc().month()).endOf('month').format('YYYY-MM-DD')] : []);

  const [selectedStartIssuedAtFilter, setStartIssuedAtFilter] = useState<string[]>([]);
  const [selectedEndIssuedAtFilter, setEndIssuedAtFilter] = useState<string[]>([]);

  // Tax Current Account
  const [selectedTaxCurrentAccountStatus, setSelectedTaxCurrentAccountStatus] = useState<Option[]>([]);
  const [selectedTaxCurrentAccountType, setSelectedTaxCurrentAccountType] = useState<Option[]>([]);
  const [selectedTaxCurrentAccountCity, setSelectedTaxCurrentAccountCity] = useState<Option[]>([]);
  const [selectedTaxCurrentAccountState, setSelectedTaxCurrentAccountState] = useState<Option[]>([]);


  // Validate if filters object is correct and can mount filters
  useEffect(() => {
    if(props.filters.length > 0) {
      if (!isValidFilterData(props.filters)) {
        setShowFilter(false);
        toastError(
          "Ocorreu um erro ao tentar montar os filtros. Contate o suporte."
        );
      } else {
        initFilter();
      }
    }
  }, []);

  useEffect(() => {
    if (props.reinitFilter) {
      initFilter()
    }
  }, [props.reinitFilter]);

  useEffect(() => {
    if (guid_client as string) {
      initFilter();
      clearFilters(true);
    }
  }, [guid_client]);

  useEffect(() => {
    if (props.clearFilters) {
      clearFilters();
    }
  }, [props.clearFilters]);

  // Initialize again if certificate size changes
  useEffect(() => {
    if (props.certificates?.length) {
      if (props.certificates.length !== certificates.length) {
        initFilter();
      }
      setCertificates(props.certificates);
    }
  }, [props.certificates]);

    // Config certificate type options
    useEffect(() => {
      setOptionsCertificateType([]);
      if (certificates) {
        certificates.forEach((info) => {
          setOptionsCertificateType(prev => [...prev, { value: info.slug as string, label: info.name as string }])
        });
      }
    }, [certificates]);

  // Config Status options based on certificate type
  useEffect(() => {
    const statusOpt = [] as Option[];
    let selectedCertificates = certificates ?? [];

    if (selectedCertificateType.length && certificates) {
      selectedCertificates = certificates.filter((certificate) =>
      selectedCertificateType.map((val) => val.value as string).includes(certificate.slug)
      );
    }

    selectedCertificates.forEach((certificate) => {
      certificate.status?.forEach((status) => {
        if (!statusOpt.filter((stat) => stat.label === status.name).length) {
          statusOpt.push({
            value: status.name + "_" + status.slug,
            label: status.name.includes("*")
              ? status.name + " (Positiva c/ efeito Negativa)"
              : status.name,
          });
        }
      });
    });

    statusOpt.push({
      value: "has-last-issued",
      label: "Certidão Anterior Válida",
    } as Option);

    setOptionsStatus(statusOpt);

    if (selectedCertificateType.length) {
      const selectedStatusValues = selectedStatus.filter((status) => statusOpt.some((stat) => stat.value === status.value));
  
      setSelectedStatus(selectedStatusValues);
    }
  }, [selectedCertificateType, certificates]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.companyFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.companyFilter]: getStringValuesFromOptions(selectedCompanies) }));
    }
  }, [selectedCompanies]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.holderFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.holderFilter]: getStringValuesFromOptions(selectedHolder) }));
    }
  }, [selectedHolder]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.companyGroupFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.companyGroupFilter]: getStringValuesFromOptions(selectedCompanyGroups) }));
    }
  }, [selectedCompanyGroups]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.companyTypeFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.companyTypeFilter]: getStringValuesFromOptions(selectedCompanyTypes) }));
    }
  }, [selectedCompanyTypes]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.companyOrPersonaTypeFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.companyOrPersonaTypeFilter]: getStringValuesFromOptions(selectedCompanyOrPersonaTypes) }));
    }
  }, [selectedCompanyOrPersonaTypes]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.companyCodeFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.companyCodeFilter]: getStringValuesFromOptions(selectedCompanyCodes) }));
    }
  }, [selectedCompanyCodes]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.certificateTypeFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.certificateTypeFilter]: getStringValuesFromOptions(selectedCertificateType) }));
    }
  }, [selectedCertificateType]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.certificateStatusFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.certificateStatusFilter]: getStringValuesFromOptions(selectedStatus) }));
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.certificateStatusValidInvalidFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.certificateStatusValidInvalidFilter]: getStringValuesFromOptions(selectedCertificateStatusValidInvalid) }));
    }
  }, [selectedCertificateStatusValidInvalid]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.certificateValidUntilFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.certificateValidUntilFilter]: getStringValuesFromOptions(selectedCertificateValidUntil) }));
    }
  }, [selectedCertificateValidUntil]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.jurisdictionFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.jurisdictionFilter]: getStringValuesFromOptions(selectedJurisdictions) }));
    }
  }, [selectedJurisdictions]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.companyStateFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.companyStateFilter]: getStringValuesFromOptions(selectedStates) }));
    }
  }, [selectedStates]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.certificateStateFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.certificateStateFilter]: getStringValuesFromOptions(selectedStates) }));
    }
  }, [selectedStates]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.companyCityFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.companyCityFilter]: getStringValuesFromOptions(selectedCities) }));
    }
  }, [selectedCities]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.companySituationFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.companySituationFilter]: getStringValuesFromOptions(selectedCompanySituation) }));
    }
  }, [selectedCompanySituation]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.companySituationIeFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.companySituationIeFilter]: getStringValuesFromOptions(selectedCompanySituationIe) }));
    }
  }, [selectedCompanySituationIe]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.certificateCityFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.certificateCityFilter]: getStringValuesFromOptions(selectedCities) }));
    }
  }, [selectedCities]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.searchTypeFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.searchTypeFilter]: getStringValuesFromOptions(selectedSearchType) }));
    }
  }, [selectedSearchType]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.companyStatusFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.companyStatusFilter]: getStringValuesFromOptions(selectedCompanyStatus) }));
    }
  }, [selectedCompanyStatus]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.personaNameFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.personaNameFilter]: getStringValuesFromOptions(selectedPersonaName) }));
    }
  }, [selectedPersonaName]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.personaDocumentFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.personaDocumentFilter]: getStringValuesFromOptions(selectedPersonaDocument) }));
    }
  }, [selectedPersonaDocument]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.kanbanPeriodFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.kanbanPeriodFilter]: getStringValuesFromOptions(selectedKanbanPeriods) }));
    }
  }, [selectedKanbanPeriods]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.kanbanScheduleFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.kanbanScheduleFilter]: getStringValuesFromOptions(selectedKanbanSchedule) }));
    }
  }, [selectedKanbanSchedule]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.kanbanCardNameFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.kanbanCardNameFilter]: getStringValuesFromOptions(selectedKanbanCardName) }));
    }
  }, [selectedKanbanCardName]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.monthFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.monthFilter]: getStringValuesFromOptions(selectedMonth) }));
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.yearFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.yearFilter]: getStringValuesFromOptions(selectedYear) }));
    }
  }, [selectedYear]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.collaboratorFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.collaboratorFilter]: getStringValuesFromOptions(selectedCollaborators) }));
    }
  }, [selectedCollaborators]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.kanbanScheduleStatusFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.kanbanScheduleStatusFilter]: getStringValuesFromOptions(selectedKanbanScheduleStatus) }));
    }
  }, [selectedKanbanScheduleStatus]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.kanbanFileFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.kanbanFileFilter]: getStringValuesFromOptions(selectedKanbanFile) }));
    }
  }, [selectedKanbanFile]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.startCreatedAtFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.startCreatedAtFilter]: selectedStartCreatedAtFilter }));
    }
  }, [selectedStartCreatedAtFilter]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.endCreatedAtFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.endCreatedAtFilter]: selectedEndCreatedAtFilter }));
    }
  }, [selectedEndCreatedAtFilter]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.startIssuedAtFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.startIssuedAtFilter]: selectedStartIssuedAtFilter }));
    }
  }, [selectedStartIssuedAtFilter]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.endIssuedAtFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.endIssuedAtFilter]: selectedEndIssuedAtFilter }));
    }
  }, [selectedEndIssuedAtFilter]);

  useEffect(() => {
    setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.userNameFilter]: getStringValuesFromOptions(selectedUserName) }));
  }, [selectedUserName]);

  useEffect(() => {
    setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.userEmailFilter]: getStringValuesFromOptions(selectedUserEmail) }));
  }, [selectedUserEmail]);

  useEffect(() => {
    setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.userStatusFilter]: getStringValuesFromOptions(selectedUserStatus) }));
  }, [selectedUserStatus]);

  // Tax Current Account
  useEffect(() => {
    if (hasFilter([FilterTypeEnum.taxCurrentAccountStatusFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.taxCurrentAccountStatusFilter]: getStringValuesFromOptions(selectedTaxCurrentAccountStatus) }));
    }
  }, [selectedTaxCurrentAccountStatus]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.taxCurrentAccountTypeFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.taxCurrentAccountTypeFilter]: getStringValuesFromOptions(selectedTaxCurrentAccountType) }));
    }
  }, [selectedTaxCurrentAccountType]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.taxCurrentAccountCityFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.taxCurrentAccountCityFilter]: getStringValuesFromOptions(selectedTaxCurrentAccountCity) }));
    }
  }, [selectedTaxCurrentAccountCity]);

  useEffect(() => {
    if (hasFilter([FilterTypeEnum.taxCurrentAccountStateFilter])) {
      setFilterDataSelected(prev => ({ ...prev, [FilterTypeEnum.taxCurrentAccountStateFilter]: getStringValuesFromOptions(selectedTaxCurrentAccountState) }));
    }
  }, [selectedTaxCurrentAccountState]);

  useEffect(() => {
    props.selectedValuesHook(filterDataSelected);
  }, [filterDataSelected]);

  const initFilter = () => {
    if(props.filters.length > 0) {
      setShowFilter(true);
      fetchFilterOptionData(props.filters);

      let filtersToOrder = props.filters;
      // Order rows
      filtersToOrder.sort((a, b) => a.position - b.position);
      // Order fields of each row
      filtersToOrder.forEach((filterRow: FilterRow) => {
        filterRow.rowFields.sort((a, b) => a.position - b.position);
      });

      setOrderedFilters(filtersToOrder);
    }
  };

  const getStringValuesFromOptions = (options: Option[]): string[] => {
    return options.map((option) => option.value as string);
  }

  const hasFilter = (filterEnums: FilterTypeEnum[]) => {
    return filterEnums.some(
            (filterName) =>
                props.filters.some((filterRow) =>
                    filterRow.rowFields.some(
                        (filterField) => filterField.name === filterName
                    )
                )
        );
  }

  const fetchFilterOptionData = async (filters: FilterRow[]) => {
    const shouldLoadCompany = hasFilter([
      FilterTypeEnum.companyFilter,
      FilterTypeEnum.companyCodeFilter,
      FilterTypeEnum.companyStateFilter,
      FilterTypeEnum.companyCityFilter,
      FilterTypeEnum.companySituationFilter,
      FilterTypeEnum.companySituationIeFilter,
      FilterTypeEnum.companyTypeFilter
    ]);

    const holderOptionsList: Option[] = [];
    if (shouldLoadCompany) {
      const companyData = await getCompanyFilterOptions(guid_client as string, props.allCompanies);

      if (companyData.status === 200) {
        const companiesOptions = companyData.response as ICompanyFilter[];
        
        const companyOptionsList: Option[] = [];
        const companyCodeOptionsList: Option[] = [];
        const stateOptionsList: Option[] = [];
        const cityOptionsList: Option[] = [];
        const situationIeOptionsList: Option[] = [];
        const companyTypeOptionsList: Option[] = [];

        companiesOptions.forEach((companyOption) => {
          companyOptionsList.push({ 
            label: `${companyOption.name} - ${formatCnpj(companyOption.cnpj)}`,
            value: companyOption.guid_company,
          });

          holderOptionsList.push({
            label: `${companyOption.name} - ${formatCnpj(companyOption.cnpj)}`,
            value: `company_${companyOption.guid_company}`,
          })

          if(
            !companyCodeOptionsList.some(option => option.value === companyOption.code)
            && companyOption.code !== null
            && companyOption.code !== ''
            ) {
            companyCodeOptionsList.push({
              label: `${companyOption.code}`,
              value: companyOption.code
            });
          }

          if(
            !situationIeOptionsList.some(option => option.value === companyOption.situationIe)
            && companyOption.situationIe !== null
            && companyOption.situationIe !== ''
            ) {
            situationIeOptionsList.push({
              label: `${companyOption.situationIe}`,
              value: companyOption.situationIe
            });
          }

          if(!companyOption.isForeign && !stateOptionsList.some(option => option.value === companyOption.state_initials)) {
            stateOptionsList.push({
              label: `${companyOption.state_name} - ${companyOption.state_initials}`,
              value: companyOption.state_initials,
            });
          }

          if(!companyOption.isForeign && !cityOptionsList.some(option => option.value === companyOption.city_name)) {
            cityOptionsList.push({
              label: `${companyOption.city_name}`,
              value: companyOption.city_name
            });
          }

          if(!companyTypeOptionsList.some(option => option.value === companyOption.type)) {
            companyTypeOptionsList.push(companyTypeValues.find((type) => type.value === companyOption.type) as Option);
          }
        });

        setOptionsCompanies(companyOptionsList.sort((a, b) => a.label.localeCompare(b.label)));
        setOptionsCompanyCode(companyCodeOptionsList.sort((a, b) => a.label.localeCompare(b.label)));
        setOptionsCompanySituationIe(situationIeOptionsList.sort((a, b) => a.label.localeCompare(b.label)));
        if (filters.some((filterRow) =>  filterRow.rowFields.some((filterField) => filterField.name === FilterTypeEnum.companyStateFilter))) {
          setOptionsStates(stateOptionsList.sort((a, b) => a.label.localeCompare(b.label)));
        }
        if (filters.some((filterRow) =>  filterRow.rowFields.some((filterField) => filterField.name === FilterTypeEnum.companyCityFilter))) {
          setOptionsCities(cityOptionsList.sort((a, b) => a.label.localeCompare(b.label)));
        }
        setOptionsCompanyType(companyTypeOptionsList);
      } else {
        toastError("Ocorreu um erro ao tentar puxar as informações das empresas");
      }
    }

    const shouldLoadPersona = hasFilter([
      FilterTypeEnum.holderFilter,
      FilterTypeEnum.personaDocumentFilter,
      FilterTypeEnum.personaNameFilter
    ]);

    if(shouldLoadPersona) {
        const filters: IFilterListPersona = {
            page: 1,
            row_by_page: '-1'
        };

        const { status, response } = await getPersonaFilterOptions(guid_client as string);

        if(status === 200) {
            const personas = response as IPersonaFilter[];
            const personaOptions: Option[] = [];
            const personaNameOptions: Option[] = [];
            const personaDocumentOptions: Option[] = [];

            personas.forEach((persona: IPersonaFilter) => {
                const personaOption: Option = {
                    value: `persona_${persona.guid_persona}`,
                    label: `${persona.name} - ${formatCpf(persona.document)}`,
                };
                personaOptions.push(personaOption);

                const personaNameOption: Option = {
                    value: `${persona.guid_persona}`,
                    label: `${persona.name}`,
                };
                personaNameOptions.push(personaNameOption);

                const personaDocumentOption: Option = {
                    value: `${persona.guid_persona}`,
                    label: `${formatCpf(persona.document)}`,
                };
                personaDocumentOptions.push(personaDocumentOption);
            });

            setOptionsHolder([...personaOptions, ...holderOptionsList]);
            setOptionsPersonaDocument(personaDocumentOptions);
            setOptionsPersonaName(personaNameOptions);
        }
    }

    const shouldLoadCompanyGroup = hasFilter([FilterTypeEnum.companyGroupFilter]);

    if (shouldLoadCompanyGroup) {
      const filters: IFilterListCompanyGroup = {
        page: 1,
        row_by_page: -1,
      };

      const companyGroupData = await listCompanyGroup(guid_client as string, filters);

      if (companyGroupData.status === 200) {
        if (companyGroupData.response.meta.pageCount !== undefined && companyGroupData.response.data !== undefined) {
          const data = companyGroupData.response.data;
          const groupOptions: any = [];
          data.map((group: any) => {
            groupOptions.push({
              value: group.guid_company_group,
              label: group.name
            });
          })

          setOptionsGroupCompany(groupOptions);
        }
      } else {
        toastError("Ocorreu um erro ao tentar puxar as informações dos grupos de empresas");
      }
    }

    const shouldLoadKanbanSchedule = hasFilter([
      FilterTypeEnum.kanbanScheduleFilter,
    ]);

    if(shouldLoadKanbanSchedule) {
      const schedules = await listSchedule(guid_client as string, { page: 1, row_by_page: -1 });
      if(schedules.status === 200 && 'meta' in schedules.response && 'data' in schedules.response) {
        const scheduleOptions: Option[] = [];
        schedules.response.data.forEach((schedule: any) => {
          scheduleOptions.push({
            value: schedule.guid_schedule,
            label: schedule.rulename
          });
        });
        setOptionsKanbanSchedule(scheduleOptions);
      }
    }

    const shouldLoadKanbanCardName = hasFilter([
      FilterTypeEnum.kanbanCardNameFilter,
    ]);

    if(shouldLoadKanbanCardName) {
      const cardNames = await getFilterCardName(guid_client as string);

      if(cardNames.status === 200) {
        const cardNameOptions: Option[] = [];
        cardNames.response.forEach((card: any) => {
          cardNameOptions.push({
            value: card.name,
            label: card.name
          });
        });
        setOptionsKanbanCardName(cardNameOptions);
      }
    }

    const shouldLoadKanbanCards = hasFilter([
      FilterTypeEnum.kanbanPeriodFilter,
      FilterTypeEnum.kanbanScheduleFilter,
    ]);

    if (shouldLoadKanbanCards) {
      const kanbanCardData = await getKanban(guid_client as string);
      if (kanbanCardData.status === 200 && kanbanCardData && kanbanCardData.response) {
        const kanbanCardList = kanbanCardData.response as ICardList[];
        if (kanbanCardList.length > 0) {
          const optionsKanbanPeriodList: Option[] = [];

          kanbanCardList.forEach((info) => {
            if (info.calculationPeriod && !optionsKanbanPeriodList.some(option => option.value === info.calculationPeriod)) {
              optionsKanbanPeriodList.push({
                value: info.calculationPeriod as string,
                label: info.calculationPeriod as string,
              });
            }
          });
          setOptionsKanbanPeriod(optionsKanbanPeriodList);
        }
      } else {
        toastError("Ocorreu um erro ao tentar puxar as informações dos cards do kanban");
      }
    }

    const shouldLoadCollaborators = hasFilter([FilterTypeEnum.collaboratorFilter]);

    if (shouldLoadCollaborators) {
      const collaboratorsData = await getClientEmployees(guid_client as string);
      if (collaboratorsData.status === 200 && 'collaborators' in collaboratorsData.response) {
        const collaboratorsList = (collaboratorsData.response as IGetEmployeesResponse).collaborators as ICollaborators[];
        if (collaboratorsList.length > 0) {
          const collaboratorsOptions: Option[] = [];

          collaboratorsList.forEach((info) => {
            if (!collaboratorsOptions.some(option => option.value === info.guid_user) && !info.users.isBlocked && info.users.isValidated) {
              collaboratorsOptions.push({
                value: info.guid_collaborator as string,
                label: (info.users.name ? info.users.name : info.users.email.split('@')[0]) as string,
              });
            }
          });
          setOptionsCollaborators(collaboratorsOptions);
        }
      } else {
        toastError("Ocorreu um erro ao tentar puxar as informações dos colaboradores da empresa");
      }
    }

    const shouldLoadCertificates = hasFilter([
      FilterTypeEnum.certificateCityFilter,
      FilterTypeEnum.certificateStateFilter,
      FilterTypeEnum.certificateStatusValidInvalidFilter,
    ]);

    if (shouldLoadCertificates) {
      const filter: IFilterListCertificate = {
        page: 1,
        row_by_page: -1,
      }
      const certificatesData = await listCnds(guid_client as string, filter);

      if (
        certificatesData.status === 200 
        && 'meta' in certificatesData.response
        && 'data' in certificatesData.response
        && certificatesData.response.meta.pageCount !== undefined 
        && certificatesData.response.data !== undefined
      ) {
        const certificatesList: ICNDListData[] = certificatesData.response.data;

        const stateOptionsList: Option[] = [];
        const cityOptionsList: Option[] = [];
        let hasStatusSemRegistro = false;

        certificatesList.forEach((certificate: ICNDListData) => {
          if(certificate.slug_status === 'no-registry') {
            hasStatusSemRegistro = true;
          }

          if(certificate.state && !stateOptionsList.some(option => option.value === certificate.state?.id_state)) {
            stateOptionsList.push({
              label: `${certificate.state.name} - ${certificate.state.initials}`,
              value: certificate.state.id_state
            });
          }

          if(certificate.city && !cityOptionsList.some(option => option.value === certificate.city?.id_city)) {
            cityOptionsList.push({
              label: `${certificate.city.name}`,
              value: certificate.city.id_city
            });
          }
        });
        
        if(hasStatusSemRegistro) {
          setOptionsCertificateStatusValidInvalid([
            { value: 'valido', label: "Válido" },
            { value: 'invalido', label: "Inválido" },
            { value: "has-last-issued", label: "Certidão Anterior Válida" },
            { value: 'error', label: "Erro" },
            { value: 'no-registry', label: "Sem Registro" },
          ]);
        } else {
          setOptionsCertificateStatusValidInvalid([
            { value: 'valido', label: "Válido" },
            { value: 'invalido', label: "Inválido" },
            { value: "has-last-issued", label: "Certidão Anterior Válida" },
            { value: 'error', label: "Erro" },
          ]);
        }

        setOptionsStates(stateOptionsList.sort((a, b) => a.label.localeCompare(b.label)));
        setOptionsCities(cityOptionsList.sort((a, b) => a.label.localeCompare(b.label)));
      } else {
        // toastError("Ocorreu um erro ao tentar puxar as informações dos certificados da empresa");
      }
    }

    const shouldLoadKanbanFiles = hasFilter([FilterTypeEnum.kanbanFileFilter]);

    if (shouldLoadKanbanFiles) {
      const allKanbanFilesData = await getAllFiles(guid_client as string);
      if (allKanbanFilesData.status === 200) {
        const kanbanFiles = allKanbanFilesData.response as IFiles[];

        const fileOptions: Option[] = [];

        kanbanFiles.forEach((kanbanFile) => {
          kanbanFile.files.forEach(file => {
            if(file.guid_file && !fileOptions.some(option => option.value === file.guid_file)) {
              fileOptions.push({
                label: `${file.filename}`,
                value: file.guid_file
              });
            }
          });
        });

        setOptionsKanbanFile(fileOptions);
      } else {
        // toastError("Ocorreu um erro ao tentar puxar as informações dos certificados da empresa");
      }
    }

    const shouldLoadUser = hasFilter([
      FilterTypeEnum.userNameFilter,
      FilterTypeEnum.userEmailFilter,
    ]);

    if(shouldLoadUser) {
      const filters: IFilterListUser = {
        page: 1,
        row_by_page: -1
      };

      const { status, response } = await listUsers(guid_client as string, filters);

      if(
          status === 200
          && response.meta.pageCount !== undefined
          && response.data !== undefined
      ) {
          const users = response.data as IUser[];
          const userNamesOptions: Option[] = [];
          const userEmailsOptions: Option[] = [];

          users.forEach((user: IUser) => {
              const userNameOption: Option = {
                  value: `${user.guid_user}`,
                  label: `${user.name}`,
              };
              userNamesOptions.push(userNameOption);

              const userEmailOption: Option = {
                value: `${user.guid_user}`,
                label: `${user.email}`,
              };
              userEmailsOptions.push(userEmailOption);
          });
          setOptionsUserName(userNamesOptions);
          setOptionsUserEmail(userEmailsOptions);
      }
    }

    // Tax Current Account
    const shouldLoadTaxCurrentAccount = hasFilter([
      FilterTypeEnum.taxCurrentAccountStatusFilter,
      FilterTypeEnum.taxCurrentAccountTypeFilter,
      FilterTypeEnum.taxCurrentAccountCityFilter,
      FilterTypeEnum.taxCurrentAccountStateFilter,
    ]);

    if (shouldLoadTaxCurrentAccount) {
      const { status, response } = await getTaxCurrentAccountFilterOptions(guid_client as string);
      if (status === 200) {
        const taxCurrentAccountStatusOptions: Option[] = [];

        (response as ITaxCurrentAccountFilter).statuses.forEach((status: any) => {
          taxCurrentAccountStatusOptions.push({
            value: status.value,
            label: status.label
          });
        });

        setOptionsTaxCurrentAccountStatus(taxCurrentAccountStatusOptions);

        const taxCurrentAccountTypeOptions: Option[] = [];
        
        (response as ITaxCurrentAccountFilter).types.forEach((type: any) => {
          taxCurrentAccountTypeOptions.push({
            value: type.value,
            label: type.label
          });
        });

        setOptionsTaxCurrentAccountType(taxCurrentAccountTypeOptions);

        const taxCurrentAccountCityOptions: Option[] = [];

        (response as ITaxCurrentAccountFilter).cities.forEach((city: any) => {
          taxCurrentAccountCityOptions.push({
            value: city.value,
            label: city.label
          });
        });

        setOptionsTaxCurrentAccountCity(taxCurrentAccountCityOptions);

        const taxCurrentAccountStateOptions: Option[] = [];
        
        (response as ITaxCurrentAccountFilter).states.forEach((state: any) => {
          taxCurrentAccountStateOptions.push({
            value: state.value,
            label: state.label
          });
        });

        setOptionsTaxCurrentAccountState(taxCurrentAccountStateOptions);
      }
    }
  };

  const toastError = (message: string) => {
    if (toastRef.current) {
      toast.close(toastRef.current);
    }

    const toastId = toast({
      title: "Ocorreu um erro",
      description: `${message}`,
      status: "error",
      duration: 5000,
      isClosable: true,
    });

    toastRef.current = toastId;
  };

  const isValidFilterData = (filters: FilterRow[]) => {
    let isValid = true;


    let positionRowValidate = Array.from(
      { length: filters.length },
      (_, i) => filters.length - i
    ).reduce((acc, curr) => acc + curr, 0);

    filters.forEach((filterRow: FilterRow) => {
      let sizes = 0;
      let positionFieldValidate = Array.from(
        { length: filterRow.rowFields.length },
        (_, i) => filterRow.rowFields.length - i
      ).reduce((acc, curr) => acc + curr, 0);

      filterRow.rowFields.forEach((filter: FilterField) => {
        sizes += filter.size;
        positionFieldValidate -= filter.position;

        if (!filterDataFields.hasOwnProperty(filter.name)) {
          isValid = false;
        }
      });

      if (sizes !== 12 || positionFieldValidate !== 0) {
        isValid = false;
      }

      positionRowValidate -= filterRow.position;
    });

    if (positionRowValidate !== 0) {
      isValid = false;
    }

    return isValid;
  };

  const countFilters = (): number => {
    let count = 0;
    count += hasFilter([FilterTypeEnum.companyFilter]) && selectedCompanies?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.companyGroupFilter]) && selectedCompanyGroups?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.companyTypeFilter]) && selectedCompanyTypes?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.companyCodeFilter]) && selectedCompanyCodes?.length ? 1 : 0;

    count += hasFilter([FilterTypeEnum.companyOrPersonaTypeFilter]) && selectedCompanyOrPersonaTypes?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.holderFilter]) && selectedHolder?.length ? 1 : 0;

    count += hasFilter([FilterTypeEnum.certificateTypeFilter]) && selectedCertificateType?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.certificateStatusFilter]) && selectedStatus?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.certificateStatusValidInvalidFilter]) && selectedCertificateStatusValidInvalid?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.certificateValidUntilFilter]) && selectedCertificateValidUntil?.length ? 1 : 0;

    count += hasFilter([FilterTypeEnum.jurisdictionFilter]) && selectedJurisdictions?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.companyStateFilter, FilterTypeEnum.certificateStateFilter]) && selectedStates?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.companyCityFilter, FilterTypeEnum.certificateCityFilter]) && selectedCities?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.searchTypeFilter]) && selectedSearchType?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.companyStatusFilter]) && selectedCompanyStatus?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.kanbanPeriodFilter]) && selectedKanbanPeriods?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.kanbanScheduleFilter]) && selectedKanbanSchedule?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.kanbanCardNameFilter]) && selectedKanbanCardName?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.monthFilter]) && selectedMonth?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.yearFilter]) && selectedYear?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.collaboratorFilter]) && selectedCollaborators?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.kanbanScheduleStatusFilter]) && selectedKanbanScheduleStatus?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.kanbanFileFilter]) && selectedKanbanFile?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.personaNameFilter]) && selectedPersonaName?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.personaDocumentFilter]) && selectedPersonaDocument?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.startCreatedAtFilter]) && selectedStartCreatedAtFilter && selectedStartCreatedAtFilter[0] && selectedStartCreatedAtFilter[0].length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.endCreatedAtFilter]) && selectedEndCreatedAtFilter && selectedEndCreatedAtFilter[0] && selectedEndCreatedAtFilter[0].length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.startIssuedAtFilter]) && selectedStartIssuedAtFilter && selectedStartIssuedAtFilter[0] && selectedStartIssuedAtFilter[0].length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.endIssuedAtFilter]) && selectedEndIssuedAtFilter && selectedEndIssuedAtFilter[0] && selectedEndIssuedAtFilter[0].length ? 1 : 0;

    count += hasFilter([FilterTypeEnum.userEmailFilter]) && selectedUserEmail?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.userNameFilter]) && selectedUserName?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.userStatusFilter]) && selectedUserStatus?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.companySituationFilter]) && selectedCompanySituation?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.companySituationIeFilter]) && selectedCompanySituationIe?.length ? 1 : 0;
  
    // Tax Current Account
    count += hasFilter([FilterTypeEnum.taxCurrentAccountStatusFilter]) && selectedTaxCurrentAccountStatus?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.taxCurrentAccountTypeFilter]) && selectedTaxCurrentAccountType?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.taxCurrentAccountCityFilter]) && selectedTaxCurrentAccountCity?.length ? 1 : 0;
    count += hasFilter([FilterTypeEnum.taxCurrentAccountStateFilter]) && selectedTaxCurrentAccountState?.length ? 1 : 0;

    return count;
  };

  const clearFilters = (persistDefaultFilters: boolean = false) => {
    setSelectedCompanies([]);
    setSelectedHolder([]);
    setSelectedCompanyGroups([]);
    setSelectedCompanyTypes([]);
    setSelectedCompanySituation([]);
    setSelectedCompanySituationIe([]);
    setSelectedCompanyOrPersonaTypes([]);
    setSelectedCompanyCodes([]);
    setSelectedCertificateType([]);
    setSelectedStatus([]);
    setSelectedCertificateValidUntil([]);
    setSelectedJurisdictions([]);
    setSelectedStates([]);
    setSelectedCities([]);
    setSelectedSearchType([]);
    setSelectedCompanyStatus([]);
    setSelectedPersonaName([]);
    setSelectedPersonaDocument([]);
    setSelectedKanbanPeriods([]);
    setSelectedKanbanSchedule([]);
    setSelectedKanbanCardName([]);
    setSelectedMonth(persistDefaultFilters ? [...optionsMonth.filter(opt => opt.value === moment().format('MM'))] : []);
    setSelectedYear(persistDefaultFilters ? [...optionsYear.filter(opt => opt.value === moment().format('YYYY'))] : []);
    setSelectedCollaborators([]);
    setSelectedKanbanScheduleStatus([]);
    setSelectedKanbanFile([]);
    setStartCreatedAtFilter(persistDefaultFilters ? [moment.utc().month(moment.utc().month()).startOf('month').format('YYYY-MM-DD')] : ['']);
    setEndCreatedAtFilter(persistDefaultFilters ? [moment.utc().month(moment.utc().month()).endOf('month').format('YYYY-MM-DD')] : ['']);
    setStartIssuedAtFilter(['']);
    setEndIssuedAtFilter(['']);
    setSelectedUserEmail([]);
    setSelectedUserName([]);
    setSelectedUserStatus([]);
    setSelectedCertificateStatusValidInvalid([]);

    // Tax Current Account
    setSelectedTaxCurrentAccountStatus([]);
    setSelectedTaxCurrentAccountType([]);
    setSelectedTaxCurrentAccountCity([]);
    setSelectedTaxCurrentAccountState([]);
  };

  const handleChangeCompany = (values: Option[]) => {setSelectedCompanies(values);};
  const handleChangeHolder = (values: Option[]) => {setSelectedHolder(values);};
  const handleChangeCompanyGroup = (values: Option[]) => {setSelectedCompanyGroups(values);};
  const handleChangeCompanyType = (values: Option[]) => {setSelectedCompanyTypes(values);};
  const handleChangeCompanySituation = (values: Option[]) => {setSelectedCompanySituation(values);};
  const handleChangeCompanySituationIe = (values: Option[]) => {setSelectedCompanySituationIe(values);};
  const handleChangeCompanyOrPersonaType = (values: Option[]) => {setSelectedCompanyOrPersonaTypes(values);};
  const handleChangeCompanyCode = (values: Option[]) => {setSelectedCompanyCodes(values);};

  const handleChangeCertificateType = (values: Option[]) => {setSelectedCertificateType(values);};
  const handleChangeStatus = (values: Option[]) => {setSelectedStatus(values);};
  const handleChangeCertificateStatusValidInvalid = (values: Option[]) => {setSelectedCertificateStatusValidInvalid(values);};
  const handleChangeCertificateValidUntil = (values: Option[]) => {setSelectedCertificateValidUntil(values);};

  const handleChangeJurisdictions = (values: Option[]) => {setSelectedJurisdictions(values);};
  const handleChangeStates = (values: Option[]) => {setSelectedStates(values);};
  const handleChangeCities = (values: Option[]) => {setSelectedCities(values);};
  const handleChangeSearchType = (values: Option[]) => {setSelectedSearchType(values);};
  const handleChangeCompanyStatus = (values: Option[]) => {setSelectedCompanyStatus(values);};
  const handleChangeKanbanPeriods = (values: Option[]) => {setSelectedKanbanPeriods(values);};
  const handleChangeKanbanSchedule = (values: Option[]) => {setSelectedKanbanSchedule(values);};
  const handleChangeKanbanCardName = (values: Option[]) => {setSelectedKanbanCardName(values);};
  const handleChangeMonth = (values: Option[]) => {setSelectedMonth(values);};
  const handleChangeYear = (values: Option[]) => {setSelectedYear(values);};
  const handleChangeCollaborator = (values: Option[]) => {setSelectedCollaborators(values);};
  const handleChangeKanbanScheduleStatus = (values: Option[]) => {setSelectedKanbanScheduleStatus(values);};
  const handleChangeKanbanFile = (values: Option[]) => {setSelectedKanbanFile(values);};

  const handleChangePersonaName = (values: Option[]) => {setSelectedPersonaName(values);};
  const handleChangePersonaDocument = (values: Option[]) => {setSelectedPersonaDocument(values);};
  const handleChangeStartCreatedAt = (value: string) => {setStartCreatedAtFilter([value]);};
  const handleChangeEndCreatedAt = (value: string) => {setEndCreatedAtFilter([value]);};
  const handleChangeStartIssuedAt = (value: string) => {setStartIssuedAtFilter([value]);};
  const handleChangeEndIssuedAt = (value: string) => {setEndIssuedAtFilter([value]);};

  const handleChangeUserName = (values: Option[]) => {setSelectedUserName(values);};
  const handleChangeUserEmail = (values: Option[]) => {setSelectedUserEmail(values);};
  const handleChangeUserStatus = (values: Option[]) => {setSelectedUserStatus(values);};

  // Tax Current Account
  const handleChangeTaxCurrentAccountStatus = (values: Option[]) => {setSelectedTaxCurrentAccountStatus(values);};
  const handleChangeTaxCurrentAccountType = (values: Option[]) => {setSelectedTaxCurrentAccountType(values);};
  const handleChangeTaxCurrentAccountCity = (values: Option[]) => {setSelectedTaxCurrentAccountCity(values);};
  const handleChangeTaxCurrentAccountState = (values: Option[]) => {setSelectedTaxCurrentAccountState(values);};

  const filterDataFields = {
    [FilterTypeEnum.companyFilter]: {
      id: 'company_filter',
      label: "Empresa",
      options: optionsCompanies,
      selected: selectedCompanies,
      onChange: handleChangeCompany,
    } as FilterDataSelect,
    [FilterTypeEnum.holderFilter]: {
      id: 'holder_filter_filter',
      label: "Titular",
      options: optionsHolder,
      selected: selectedHolder,
      onChange: handleChangeHolder,
    } as FilterDataSelect,
    [FilterTypeEnum.companyGroupFilter]: {
      id: 'company_group_filter',
      label: "Grupo de empresas",
      options: optionsGroupCompany,
      selected: selectedCompanyGroups,
      onChange: handleChangeCompanyGroup,
    } as FilterDataSelect,
    [FilterTypeEnum.companyTypeFilter]: {
      id: 'company_type_filter',
      label: "Tipo",
      options: optionsCompanyType,
      selected: selectedCompanyTypes,
      onChange: handleChangeCompanyType,
    } as FilterDataSelect,
    [FilterTypeEnum.companySituationFilter]: {
      id: 'company_situation_filter',
      label: "Situação Cadastral",
      options: optionsCompanySituation,
      selected: selectedCompanySituation,
      onChange: handleChangeCompanySituation,
    } as FilterDataSelect,
    [FilterTypeEnum.companySituationIeFilter]: {
      id: 'company_situation_ie_filter',
      label: "Situação Inscrição Estadual",
      options: optionsCompanySituationIe,
      selected: selectedCompanySituationIe,
      onChange: handleChangeCompanySituationIe,
    } as FilterDataSelect,
    [FilterTypeEnum.companyOrPersonaTypeFilter]: {
      id: 'companyor_persona_type_filter_filter',
      label: "Tipo",
      options: optionsCompanyOrPersonaType,
      selected: selectedCompanyOrPersonaTypes,
      onChange: handleChangeCompanyOrPersonaType,
    } as FilterDataSelect,
    [FilterTypeEnum.companyCodeFilter]: {
      id: 'company_code_filter',
      label: "Código",
      options: optionsCompanyCode,
      selected: selectedCompanyCodes,
      onChange: handleChangeCompanyCode,
    } as FilterDataSelect,
    [FilterTypeEnum.certificateTypeFilter]: {
      id: 'certificate_type_filter',
      label: "Tipo de Certidão",
      options: optionsCertificateType,
      selected: selectedCertificateType,
      onChange: handleChangeCertificateType,
    } as FilterDataSelect,
    [FilterTypeEnum.certificateStatusFilter]: {
      id: 'certificate_status_filter',
      label: "Status",
      options: optionsStatus,
      selected: selectedStatus,
      onChange: handleChangeStatus,
    } as FilterDataSelect,
    [FilterTypeEnum.certificateStatusValidInvalidFilter]: {
      id: 'certificate_status_valid_invalid_filter',
      label: "Status",
      options: optionsCertificateStatusValidInvalid,
      selected: selectedCertificateStatusValidInvalid,
      onChange: handleChangeCertificateStatusValidInvalid,
    } as FilterDataSelect,
    [FilterTypeEnum.certificateValidUntilFilter]: {
      id: 'valid_until_filter',
      label: "Prazos",
      options: optionsCertificateValidUntil,
      selected: selectedCertificateValidUntil,
      onChange: handleChangeCertificateValidUntil,
      isSingleSelect: true,
    } as FilterDataSelect,
    [FilterTypeEnum.jurisdictionFilter]: {
      id: 'jurisdiction_filter',
      label: "Jurisdição",
      options: optionsJurisdictions,
      selected: selectedJurisdictions,
      onChange: handleChangeJurisdictions,
    } as FilterDataSelect,
    [FilterTypeEnum.companyStateFilter]: {
      id: 'company_state_filter',
      label: "Estados",
      options: optionsStates,
      selected: selectedStates,
      onChange: handleChangeStates,
    } as FilterDataSelect,
    [FilterTypeEnum.certificateStateFilter]: {
      id: 'certificate_state_filter',
      label: "Estados",
      options: optionsStates,
      selected: selectedStates,
      onChange: handleChangeStates,
    } as FilterDataSelect,
    [FilterTypeEnum.companyCityFilter]: {
      id: 'city_filter',
      label: "Cidades",
      options: optionsCities,
      selected: selectedCities,
      onChange: handleChangeCities,
    } as FilterDataSelect,
    [FilterTypeEnum.certificateCityFilter]: {
      id: 'certificate_city_filter',
      label: "Cidades",
      options: optionsCities,
      selected: selectedCities,
      onChange: handleChangeCities,
    } as FilterDataSelect,
    [FilterTypeEnum.searchTypeFilter]: {
      id: 'search_type_filter',
      label: "Tipo de pesquisa",
      options: optionsSearchType,
      selected: selectedSearchType,
      onChange: handleChangeSearchType,
    } as FilterDataSelect,
    [FilterTypeEnum.companyStatusFilter]: {
      id: 'company_status_filter',
      label: "Status da empresa",
      options: optionsCompanyStatus,
      selected: selectedCompanyStatus,
      onChange: handleChangeCompanyStatus,
    } as FilterDataSelect,
    [FilterTypeEnum.personaNameFilter]: {
      id: 'persona_name_filter_filter',
      label: "Nome",
      options: optionsPersonaName,
      selected: selectedPersonaName,
      onChange: handleChangePersonaName,
    } as FilterDataSelect,
    [FilterTypeEnum.personaDocumentFilter]: {
      id: 'persona_document_filter_filter',
      label: "CPF",
      options: optionsPersonaDocument,
      selected: selectedPersonaDocument,
      onChange: handleChangePersonaDocument,
    } as FilterDataSelect,
    [FilterTypeEnum.kanbanPeriodFilter]: {
      id: 'kanban_period_filter',
      label: "Período de Apuração",
      options: optionsKanbanPeriod,
      selected: selectedKanbanPeriods,
      onChange: handleChangeKanbanPeriods,
    } as FilterDataSelect,
    [FilterTypeEnum.kanbanScheduleFilter]: {
      id: 'kanban_schedule_filter',
      label: "Agendamento",
      options: optionsKanbanSchedule,
      selected: selectedKanbanSchedule,
      onChange: handleChangeKanbanSchedule,
    } as FilterDataSelect,
    [FilterTypeEnum.kanbanCardNameFilter]: {
      id: 'kanban_card_name_filter',
      label: "Cards",
      options: optionsKanbanCardName,
      selected: selectedKanbanCardName,
      onChange: handleChangeKanbanCardName,
    } as FilterDataSelect,
    [FilterTypeEnum.monthFilter]: {
      id: 'month_filter',
      label: "Mês",
      options: optionsMonth,
      selected: selectedMonth,
      onChange: handleChangeMonth,
    } as FilterDataSelect,
    [FilterTypeEnum.yearFilter]: {
      id: 'year_filter',
      label: "Ano",
      options: optionsYear,
      selected: selectedYear,
      onChange: handleChangeYear,
    } as FilterDataSelect,
    [FilterTypeEnum.collaboratorFilter]: {
      id: 'collaborator_filter',
      label: "Responsável",
      options: optionsCollaborators,
      selected: selectedCollaborators,
      onChange: handleChangeCollaborator,
    } as FilterDataSelect,
    [FilterTypeEnum.kanbanScheduleStatusFilter]: {
      id: 'kanban_schedule_status_filter',
      label: "Status do agendamento",
      options: optionsKanbanScheduleStatus,
      selected: selectedKanbanScheduleStatus,
      onChange: handleChangeKanbanScheduleStatus,
    } as FilterDataSelect,
    [FilterTypeEnum.kanbanFileFilter]: {
      id: 'kanban_file_filter',
      label: "Arquivos",
      options: optionsKanbanFile,
      selected: selectedKanbanFile,
      onChange: handleChangeKanbanFile,
    } as FilterDataSelect,
    [FilterTypeEnum.startCreatedAtFilter]: {
      id: 'start_created_at_filter',
      label: "Data de consulta inicial:",
      placeholder: 'Data Consulta Inicio',
      selected: selectedStartCreatedAtFilter,
      onChange: handleChangeStartCreatedAt,
      isDate: true,
    } as FilterDataDate,
    [FilterTypeEnum.endCreatedAtFilter]: {
      id: 'end_created_at_filter',
      label: "Data de consulta final:",
      placeholder: 'Data Consulta Final',
      selected: selectedEndCreatedAtFilter,
      onChange: handleChangeEndCreatedAt,
      isDate: true,
    } as FilterDataDate,
    [FilterTypeEnum.startIssuedAtFilter]: {
      id: 'start_issued_at_filter',
      label: "Data de emissão inicial:",
      placeholder: 'Data Emissão Inicio',
      selected: selectedStartIssuedAtFilter,
      onChange: handleChangeStartIssuedAt,
      isDate: true,
    } as FilterDataDate,
    [FilterTypeEnum.endIssuedAtFilter]: {
      id: 'end_issued_at_filter',
      label: "Data de emissão final:",
      placeholder: 'Data Emissão Final',
      selected: selectedEndIssuedAtFilter,
      onChange: handleChangeEndIssuedAt,
      isDate: true,
    } as FilterDataDate,
    [FilterTypeEnum.userNameFilter]: {
      id: 'user_name_filter',
      label: "Nome",
      options: optionsUserName,
      selected: selectedUserName,
      onChange: handleChangeUserName,
    } as FilterDataSelect,
    [FilterTypeEnum.userEmailFilter]: {
      id: 'user_email_filter',
      label: "E-mail",
      options: optionsUserEmail,
      selected: selectedUserEmail,
      onChange: handleChangeUserEmail,
    } as FilterDataSelect,
    [FilterTypeEnum.userStatusFilter]: {
      id: 'user_status_filter',
      label: "Status",
      options: optionsUserStatus,
      selected: selectedUserStatus,
      onChange: handleChangeUserStatus,
    } as FilterDataSelect,

    // Tax Current Account
    [FilterTypeEnum.taxCurrentAccountStatusFilter]: {
      id: 'tax_current_account_status_filter',
      label: "Status",
      options: optionsTaxCurrentAccountStatus,
      selected: selectedTaxCurrentAccountStatus,
      onChange: handleChangeTaxCurrentAccountStatus,
    } as FilterDataSelect,
    [FilterTypeEnum.taxCurrentAccountTypeFilter]: {
      id: 'tax_current_account_type_filter',
      label: "Tipo do Relatório",
      options: optionsTaxCurrentAccountType,
      selected: selectedTaxCurrentAccountType,
      onChange: handleChangeTaxCurrentAccountType,
    } as FilterDataSelect,
    [FilterTypeEnum.taxCurrentAccountCityFilter]: {
      id: 'tax_current_account_city_filter',
      label: "Cidades",
      options: optionsTaxCurrentAccountCity,
      selected: selectedTaxCurrentAccountCity,
      onChange: handleChangeTaxCurrentAccountCity,
    } as FilterDataSelect,
    [FilterTypeEnum.taxCurrentAccountStateFilter]: {
      id: 'tax_current_account_state_filter',
      label: "Estados",
      options: optionsTaxCurrentAccountState,
      selected: selectedTaxCurrentAccountState,
      onChange: handleChangeTaxCurrentAccountState,
    } as FilterDataSelect,
  } as FilterDataFields;

  const renderRow = (filterRow: FilterRow) => {
    return (
      <Flex className="row" p={'16px 8px 0 8px'}>
        {filterRow.rowFields.map((filterField: FilterField) => {
          return renderField(filterField);
        })}
      </Flex>
    );
  };

  const renderField = (filterRow: FilterField) => {
    const filterDataField = filterDataFields[filterRow.name];

    return (
      <Flex flexGrow={1} gap={2} display="block" width={`${(filterRow.size/12)*100}%`}>
        {
          (filterDataField as FilterDataDate).isDate ? (
            <Flex alignItems="center" gap={2} direction="column" pr={2} justifyContent="stretch" flexBasis={0} flexGrow={1}>
              <Flex direction="column" w="100%">
                <Text fontFamily="Poppins-Medium" color="#303030" fontSize="14px">
                  {filterDataField.label}
                </Text> 
                <DateFilter 
                  value={filterDataField.selected[0]}
                  onChange={(e: { target: { value: string } }) => (filterDataField as FilterDataDate).onChange(e.target.value)}
                  placeholder={(filterDataField as FilterDataDate).placeholder} 
                />
              </Flex>
            </Flex>
          ) : (filterDataField as FilterDataSelect).isSingleSelect ? 
            (
              <SingleSelect
              key={filterDataField.id}
              options={(filterDataField as FilterDataSelect).options}
              onChange={filterDataField.onChange}
              value={filterDataField.selected}
              label={filterDataField.label}
              isSelectAll={false}
              menuPlacement={"bottom"}
            />
            )
          : (
            <MultiSelectWithCheckbox
              key={filterDataField.id}
              options={(filterDataField as FilterDataSelect).options}
              onChange={filterDataField.onChange}
              value={filterDataField.selected}
              label={filterDataField.label}
              isSelectAll={true}
              menuPlacement={"bottom"}
            />
          )
        }
      </Flex>
    );
  };

  return (
    <Flex
      border="1px solid var(--primary-600)"
      borderRadius="6px"
      display="flex"
      flexDirection="column"
      paddingBottom="3px"
      width="100%"
      bg="white"
      fontSize="16px"
      fontWeight="var(--bs-body-font-weight)"
      textAlign={'start'}
    >
      <Flex 
        justifyContent="space-between"
        p="6px"
        borderBottom={ isFilterOpen ? '1px' : undefined }
        borderColor="gray.300"
      >

        <Flex
          alignItems="center"
          gap="8px"
          onClick={() => setFilterOpen(!isFilterOpen)}
          _hover={{ cursor: "pointer" }}
        >
          <Flex
            width="24px"
            height="24px"
            color="var(--primary-500)"
            bg="#dee4ed"
            borderRadius="4px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize="12px"
          >
            {countFilters()}
          </Flex>
          <Text
            className="text-filter"
            fontFamily="Poppins-Medium"
            fontSize="12px"
          >
            Filtros
          </Text>
          <Image
            className={isFilterOpen ? "rotate" : "rotate-reverse"}
            src="/icons/arrow-chevron-black.svg"
          />
        </Flex>

        <Flex width="20%" justifyContent="end" alignItems="center">
          <Text
            color={
              countFilters() > 0
                ? "var(--black-gray-gray-900)"
                : "var(--black-gray-gray-300)"
            }
            fontFamily="Poppins-Medium"
            fontWeight={500}
            fontSize="12px"
            _hover={{
              color: "#4b4eff",
              cursor: "pointer",
            }}
            onClick={() => clearFilters()}
          >
            Limpar filtros
          </Text>
        </Flex>
      </Flex>

      <Flex
        bg="white"
        direction="column"
        paddingBottom="13px"
        display={isFilterOpen ? "flex" : "none"}
      >
        {showFilter ? (
          orderedFilters.map((filterRow: FilterRow) => {
            return renderRow(filterRow);
          })
        ) : (
          <Flex display={'flex'}  justifyContent={'center'} alignItems={'center'}>
            <Text fontFamily={'Poppins-Medium'}>Algo deu errado no carregamento dos filtros. Entre em contato com o suporte.</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
