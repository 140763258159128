import { useEffect, useState } from "react";
import { FilterDataSelected, FilterRow, Filters, FilterTypeEnum } from "../../../../../components/Filters";
import { Datatables } from "../../../../../components/Table/Datatables";
import { IColumn, IData, IDataMeta, ITableMetaData } from "../../../../../components/Table/table.interface";
import { stateRegionForTag } from "../../../../../components/Table/utils";
import { IClientCompanies } from "../../../../../_services/interface/company.interface";
import { formatCnpj } from "../../../Companies/Company/util/cnpj";

interface IAutomationSelectCompaniesProps {
  guid_client: string;
  companies: IClientCompanies[];
  isLoading: boolean;
  selecteds: any[];
  setSelecteds: (selecteds: any[]) => void;
}

export const AutomationSelectCompanies = (props: IAutomationSelectCompaniesProps) => {
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);
  const [companyChecked, setCompanyChecked] = useState<string[]>([]);
  const [companyMetaData, setCompanyMetaData] = useState<ITableMetaData|undefined>();
  const [filteredCompanies, setFilteredCompanies] = useState<IData<any>|null>(null);


  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.companyFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.companyGroupFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.companyTypeFilter,
          position: 3,
          size: 3,
        },
      ],
      position: 1
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.companyCodeFilter,
          position: 1,
          size: 3,
        },
        {
          name: FilterTypeEnum.companyStateFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.companyCityFilter,
          position: 3,
          size: 6,
        }
      ],
      position: 2
    }
  ] as FilterRow[];

  const columns = [
    {
      title: 'Tipo',
      name: 'type',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Matriz',
          value: 'holding',
          color: '#ECFCCB',
          textColor: '#365314'
        },
        {
          text: 'Filial',
          value: 'subsidiary',
          color: '#C4F1F9',
          textColor: '#065666'
        },
      ],
    },
    {
      title: 'CNPJ',
      name: 'cnpj',
      formatter: formatCnpj,
      sortable: true,
      align: 'center',
    },
    {
      title: 'Nome - (Cód)',
      name: 'nameWithCode',
      orderFieldName: 'name',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Estado',
      name: 'initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'state'
      },
    },
    {
      title: 'Cidade',
      name: 'city',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    }
  ] as IColumn[];

  useEffect(() => {
    if (companyMetaData && props.companies && filterDataSelected) {
      const filteredCompanies = filterCompanies(props.companies, filterDataSelected);
      const orderedCompanies = sortCompanies(companyMetaData, filteredCompanies, companyChecked);

      const { currentPage, totalPages, rowByPage } = companyMetaData;
      
      const data = orderedCompanies;
      const start = (currentPage - 1) * rowByPage;
      const end = start + rowByPage;
      const paginatedData = data.slice(start, end);

      const meta: IDataMeta = {
        currentPage,
        isFirstPage: currentPage === 1,
        isLastPage: currentPage === totalPages,
        nextPage: currentPage === totalPages ? null : currentPage + 1,
        pageCount: Math.ceil(orderedCompanies.length / rowByPage),
        previousPage: currentPage === 1 ? null : currentPage - 1,
        totalCount: orderedCompanies.length
      }

      setFilteredCompanies({
        data: paginatedData,
        meta
      });
    }
  }, [companyMetaData, props.companies, filterDataSelected]);

  useEffect(() => {
    props.setSelecteds(companyChecked);
  }, [companyChecked])

  const sortCompanies = (
    companyMetaData: ITableMetaData, 
    companies: IClientCompanies[], 
    companyChecked: string[]
  ): IClientCompanies[] => {  
    // Mover os registros dentro de companyChecked para o início do array
    const checkedCompanies = companies.filter(company => companyChecked.includes(company.guid_company));
    const uncheckedCompanies = companies.filter(company => !companyChecked.includes(company.guid_company));
  
    const orderField = companyMetaData.orderField as keyof IClientCompanies;
    const orderDirection = companyMetaData.orderDirection;
  
    const compareFunction = (a: IClientCompanies, b: IClientCompanies): number => {
      const aValue = a[orderField];
      const bValue = b[orderField];
  
      if (aValue === undefined || bValue === undefined) {
        return 0;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return orderDirection === 'asc' ? comparison : -comparison;
      }
  
      if (aValue < bValue) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    };
  
    // Ordenar os uncheckedCompanies de acordo com a orderField e orderDirection
    uncheckedCompanies.sort(compareFunction);
    checkedCompanies.sort(compareFunction);
  
    // Combinar as listas checkedCompanies e sorted uncheckedCompanies
    const sortedData = [...checkedCompanies, ...uncheckedCompanies];
    
    return sortedData;
  };

  const filterCompanies = (orderedCompanies: IClientCompanies[], filterDataSelected: FilterDataSelected): IClientCompanies[] => {
    let companies = orderedCompanies;

    if (orderedCompanies.length > 0) {
      if(filterDataSelected[FilterTypeEnum.companyCityFilter].length > 0) {
        companies = companies.filter((company) => filterDataSelected[FilterTypeEnum.companyCityFilter].includes(company.city));
      }

      if(filterDataSelected[FilterTypeEnum.companyCodeFilter].length > 0) {
        companies = companies.filter((company) => filterDataSelected[FilterTypeEnum.companyCodeFilter].includes(company.companyCode));
      }

      if(filterDataSelected[FilterTypeEnum.companyTypeFilter].length > 0) {
        companies = companies.filter((company) => filterDataSelected[FilterTypeEnum.companyTypeFilter].includes(company.type === 'holding' ? '0' : '1'));
      }

      if(filterDataSelected[FilterTypeEnum.companyStateFilter].length > 0) {
        companies = companies.filter((company) => filterDataSelected[FilterTypeEnum.companyStateFilter].includes(company.initials));
      }

      if(filterDataSelected[FilterTypeEnum.companyFilter].length > 0) {
        companies = companies.filter((company) => filterDataSelected[FilterTypeEnum.companyFilter].includes(company.guid_company));
      }

      if(filterDataSelected[FilterTypeEnum.companyGroupFilter].length > 0) {
        companies = companies.filter((company) => company.groups.some((r) => filterDataSelected[FilterTypeEnum.companyGroupFilter].includes(r.guid_company_group)));
      }

      // Criando uma lista de empresas filtradas
      let filteredCompanies = companies;

      // Adicionando empresas selecionadas à lista filtrada
      filteredCompanies = filteredCompanies.concat(props.companies.filter(company => companyChecked.includes(company.guid_company)));

      // Removendo duplicatas (se houver)
      filteredCompanies = Array.from(new Set(filteredCompanies));

      return filteredCompanies;
    }

    return [];
  }

  const selectAll = () => {
    if(filterDataSelected) {
      const filteredCompanies = filterCompanies(props.companies, filterDataSelected);

      if (companyChecked.length === filteredCompanies.filter((company) => company.isActive).length && companyChecked.length > 0) {
        setCompanyChecked([]);
      } else {
        setCompanyChecked(filteredCompanies.filter((company) => company.isActive).map((x): string => x.guid_company));
      }
    }
  }

  return (
    <>
      <Filters
        filters={filterRows}
        selectedValuesHook={setFilterDataSelected}
      />

      <Datatables
        name="Empresas"
        columns={columns}
        metaDataHook={setCompanyMetaData}
        data={filteredCompanies}
        isLoading={props.isLoading}
        showTotalRows={true}
        heigth="400px"
        checkBox={{
          selecteds: companyChecked,
          setSelecteds: setCompanyChecked,
          checkAll: selectAll,
          key: 'guid_company'
        }}
      />
    </>
  );
};
