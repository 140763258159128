import { Flex, Image, RadioGroup, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { TaxlyInput, TaxlyInputPassword } from "../../../../components/Input";
import { FormButton } from "../../../../components/login/button.component";
import { configCompany, getConfigCompany } from "../../../../_services/config.service";

interface ICompanyProps {
  refreshData: boolean;
  guid_client: string;
}

interface ICompanyForm {
  field1: string|null;
  field2: string|null;
  field3: string|null;
  isActive: boolean;
}

export const Company = (props: ICompanyProps) => {
  const toast = useToast();

  const YupCompanyChangeForm = Yup.object().shape({
    field1: Yup.string().nullable(),
    field2: Yup.string().nullable(),
    field3: Yup.string().nullable(),
    isActive: Yup.boolean(),
  });

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<ICompanyForm>({ resolver: yupResolver(YupCompanyChangeForm) });

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleFormInfo = async (data: ICompanyForm) => {
    setSubmitDisabled(true);
    const {status, response} = await configCompany(props.guid_client, {
      field1: data.field1,
      field2: data.field2,
      field3: data.field3,
      isActive: data.isActive,
    });

    if(status === 200) {
      toast({
        title: 'Configuração Atualizada',
        description: 'Os dados foram atualizados com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });
    }

    if(status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });
    } else if (status === 400) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao atualizar as informações',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
    }
    
    setSubmitDisabled(false);
  }

  const configFields = async () => {
    const {status, response} = await getConfigCompany(props.guid_client);

    if(
      status === 200 
      && response
      && 'field1' in response
      && 'field2' in response
      && 'field3' in response
      && 'isActive' in response
    ) {
      const {field1, field2, field3, isActive} = response;
      setValue('field1', field1);
      setValue('field2', field2);
      setValue('field3', field3);
      setIsActive(isActive)
    }
  }

  useEffect(() => {
    configFields();
  }, [])

  useEffect(() => {
    if(props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  useEffect(() => {
    setValue('isActive', isActive);
  }, [isActive])

  return (
    <Flex direction="column" w="100%">
      <Flex marginBottom="32px" direction="column">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Campos personalizados</Text>

        <Text
          fontSize="12px"
          fontFamily="Poppins-Medium"
          fontWeight="400"
          color="#4A5568"
          fontStyle="italic"
        >
          Ao ativar essa opção, você poderá configurar campos personalizados ao Cadastrar ou Editar uma empresa.
        </Text>

        <Text
          fontSize="12px"
          fontFamily="Poppins-Medium"
          fontWeight="400"
          color="#4A5568"
          fontStyle="italic"
        >
          Essa configuração será aplicada para toda a sua organização e os demais usuários com permissão de administrador poderão Visualizar e Editar os campos criados.
        </Text>
      </Flex>

      <Flex width="100%" flexDirection="column" gap="24px" mb="35px">
        <Flex align="start" _hover={{ cursor: "pointer" }} gap={4} onClick={() => setIsActive(!isActive)}>
          {isActive ? (
            <Image w="40px" src="/icons/switch-blue.svg" />
          ) : (
            <Image w="40px" src="/icons/switch-gray.svg" />
          )}
          <Flex direction="column">
            <Text 
              color="#000"
              fontFamily="Poppins-Medium"
              fontSize="12px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="140%"
            >
              Ativar campos personalizados
            </Text>
          </Flex>
        </Flex>

        <Text
          color="#4A5568"
          fontFamily="Poppins-Medium"
          fontSize="12px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
        >
          Defina abaixo os nomes de cada campo. Esses nomes serão visíveis em relatórios e tabelas na taxly (como colunas). Exemplo: “Nível de Serviço”.
        </Text>

        <Flex gap="15px">
          <Flex w="33%" flexDirection="column">
            <TaxlyInput
              placeholder="Nome do campo 1" 
              label={'Campo 1'}
              disabled={!isActive}
              {...register("field1")}
            />
            {errors.field1 && <Text color="red.500" fontSize="sm">{errors.field1.message}</Text>}
          </Flex>

          <Flex w="33%" flexDirection="column">
            <TaxlyInput
              placeholder="Nome do campo 2" 
              label={'Campo 2'}
              disabled={!isActive}
              {...register("field2")}
            />
            {errors.field2 && <Text color="red.500" fontSize="sm">{errors.field2.message}</Text>}
          </Flex>

          <Flex w="33%" flexDirection="column">
            <TaxlyInput
              placeholder="Nome do campo 3" 
              label={'Campo 3'}
              disabled={!isActive}
              {...register("field3")}
            />
            {errors.field3 && <Text color="red.500" fontSize="sm">{errors.field3.message}</Text>}
          </Flex>
        </Flex>
      </Flex>

      <hr />

      <Flex width="100%" mt="35px" justifyContent="flex-start">
        <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>
          Salvar alterações
        </FormButton>
      </Flex>
    </Flex>
  );
}
