import { ChevronDownIcon } from '@chakra-ui/icons';
import { Flex, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import 'react-multi-email/dist/style.css';
import { ICertificate } from "../../../_services/interface/certificate.interface";
import { ConfigPanelSidebar } from './ConfigPanelSidebar';
import { Account } from './content/Account';
import { Certificate } from './content/Certificate';
import { Company } from './content/Company';
import { Display } from './content/Display';
import { TaxCurrentAccount } from './content/TaxCurrentAccount';
import { TaxKanban } from './content/TaxKanban';

interface IConfigPanelProps {
  guid_client: string;
  certificates: ICertificate[];
  hasChangeHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConfigPanel = (props: IConfigPanelProps) => {
  const [selectedContent, setSelectedContent] = useState<string>('account');
  const [refreshData, setRefreshData] = useState<boolean>(false);

  const getContent = (): React.ReactNode => {
    switch (selectedContent) {
      case 'account':
        return <Account refreshData />;
      case 'display':
        return <Display refreshData guid_client={props.guid_client} />;
      case 'company':
        return <Company refreshData guid_client={props.guid_client} />;
      case 'taxKanban':
        return <TaxKanban refreshData guid_client={props.guid_client} />;
      case 'certificate':
        return <Certificate refreshData guid_client={props.guid_client} />;
      case 'taxCurrentAccount':
        return <TaxCurrentAccount refreshData guid_client={props.guid_client} />;
      default:
        return <></>;
    }
  }

  useEffect(() => {
    setRefreshData(true);

    setTimeout(() => {
      setRefreshData(false);
    }, 1000);
  }, [selectedContent]);

  return (<>
    <Flex direction="column" w="100%" p="0" flexGrow="1" flexShrink="1" pr="30px" mt="10px">
      <Flex flexDirection="row" justifyContent="space-between" borderBottom="1px" borderBottomColor="gray.300" pb={4}>
        <Text fontSize="18px" mt={8} fontFamily="Poppins-Medium">
          Configurações
        </Text>
      </Flex>

      <Flex 
        width="100%"
        height="100%"
        marginTop="35px"
        justifyContent={'space-between'}
      >
        <ConfigPanelSidebar
          setSelectedContent={setSelectedContent}
          selectedContent={selectedContent}
        />

        <Flex
          backgroundColor="white"
          height={'100%'}
          width="75%"
          padding="24px 16px"
        >
          {getContent()}
        </Flex>
      </Flex>
    </Flex>
  </>)
}
