import {
  Flex,
  Image,
  Text,
  useToast
} from "@chakra-ui/react"
import React, {useEffect, useRef, useState} from "react";
import 'moment/locale/pt-br';
import useLoginFormStore from "../../../store/useLoginFormStore";
import {IResponse} from "../../../_services/interface/user.interface";
import {checkCollaboratorPermission} from "../Users/utils/checkPermission";
import {IFilterListPersona, IPersona} from "../../../_services/interface/persona.interface";
import {listPersonas, postEditPersona} from "../../../_services/persona.service";
import {CreatePersonaModal} from "./modals/CreatePersonaModal";
import { FilterDataSelected, FilterRow, FilterTypeEnum, Filters } from "../../../components/Filters";
import { Datatables } from "../../../components/Table/Datatables";
import { IAction, IColumn, IData, ITableMetaData } from "../../../components/Table/table.interface";
import { EditPersonaModal } from "./modals/EditPersonaModal";
import { stateRegionForTag } from "../../../components/Table/utils";

interface IPersonaPanelProps {
  guid_client: string;
  onUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PersonaPanel = (props: IPersonaPanelProps) => {
    const { validate } = useLoginFormStore();
    const toast = useToast();

    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [isLoading, setLoadingState] = useState<boolean>(false);
    const [personaList, setPersonaList] = useState<IData<IPersona>|null>(null);
    const [clearFilters, setClearFilters] = useState<boolean>(false);
    const [metaData, setMetaData] = useState<ITableMetaData|undefined>();
    const [currentPersona, setCurrentPersona] = useState<IPersona|null>(null);
    const [openEditPersonaModal, setOpenEditPersonaModal] = useState<boolean>(false);
    const [countPersonas, setCountPersonas] = useState<number>(0);

    const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

    const toastIdRef = useRef<any>()

    /**
     * Handle inactive and active profile
     *
     * @param persona
     */
    const handleToggleActivePerson = async (persona: IPersona) => {
        toastIdRef.current = toast({
            title: `${persona.isActive ? 'Desativar' : 'Ativando'} Pessoa`,
            description: `Estamos ${persona.isActive ? 'desativando' : 'ativando'} a pessoa ${persona.name}.`,
            status: 'loading',
            isClosable: false,
            duration: null,
        });

        const { status } = await postEditPersona(
            props.guid_client,
            persona.guid_persona,
            {
                isActive: !persona.isActive,
            }
        );

        if (toastIdRef.current) {
            toast.close(toastIdRef.current)
        }

        if (status === 200) {
            toast({
                title: `Pessoa física ${persona.isActive ? 'desativada' : 'ativada'}`,
                description: `A pessoa física ${persona.name} foi ${persona.isActive ? 'desativada' : 'ativada'}.`,
                status: 'success',
                isClosable: true,
                duration: 5000,
            })
        } else {
            toast({
                title: `Ocorreu um erro ao ${persona.isActive ? 'desativar' : 'ativar'} a pessoa física.`,
                description: `A pessoa física ${persona.name} não foi ${persona.isActive ? 'desativar' : 'ativada'}.`,
                status: 'error',
                isClosable: true,
                duration: 5000,
            })
        }

        setRefreshData(true)
    }

    const fetchListPersonaData = async () => {
      if (props.guid_client) {
        setLoadingState(true);

        const filters = getFilter();
        const { status, response } = await listPersonas(props.guid_client, filters);
        if (
            status === 200
            && 'meta' in response 
            && 'data' in response
            && response.meta.pageCount !== undefined
            && response.data !== undefined
        ) {
            setPersonaList(response);
            setCountPersonas(response.meta.totalCount);
        } else {
            toastError();
        }
        setLoadingState(false);
      }
    }

    const toastError = () => {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações dos usuários',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
    }

    const getFilter = (): IFilterListPersona => {
        const validFilterField = (field: string | string[] | undefined): boolean => {
          if (field) {
            if (typeof field === 'string') {
              if (field.trim() !== '') {
                return true;
              }
            } else if (field instanceof Array) {
              if (field.length > 0) {
                return true;
              }
            }
          }
          return false;
        }

        const documents: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.personaDocumentFilter] : [];
        const names: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.personaNameFilter] : [];
        const guidPersonas = [...new Set([...documents, ...names])];

        return {
          page: metaData?.currentPage ?? 1,
          row_by_page: metaData?.rowByPage.toString() ?? '-1',
          order_field: metaData?.orderField ?? 'createdAt',
          order_direction: metaData?.orderDirection ?? 'desc',
            ...(validFilterField(guidPersonas) ? {guid_persona: guidPersonas.join(',')} : {}),
        };
    }

    const filterRowsCertificate = [
        {
          rowFields: [
            {
              name: FilterTypeEnum.personaNameFilter,
              position: 1,
              size: 6,
            },
            {
              name: FilterTypeEnum.personaDocumentFilter,
              position: 2,
              size: 6,
            },
          ],
          position: 1
        },
    ] as FilterRow[];

    useEffect(() => {
        setRefreshData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        metaData,
        filterDataSelected,
        props.guid_client,
    ]);

    useEffect(() => {
        if(refreshData) {
          fetchListPersonaData();
          setRefreshData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        refreshData
    ]);

    const columns = [
      {
        title: 'Nome',
        name: 'name',
        sortable: true,
        align: 'left',
        elipse: {
          columnName: 'isScheduled',
          tooltipTextTrue: 'Agendada a pesquisa de certidões',
          tooltipTextFalse: 'Não agendada a pesquisa de certidões',
          validation: (isScheduled: boolean) => {
            return isScheduled;
          }
        }
      },
      {
        title: 'CPF',
        name: 'document_formated',
        orderFieldName: 'document',
        sortable: true,
        align: 'center',
      },
      {
        title: 'CEP',
        name: 'cep_formated',
        orderFieldName: 'cep',
        sortable: true,
        align: 'center',
      },
      {
        title: 'Estado',
        name: 'state_initials',
        orderFieldName: 'state.initials',
        sortable: true,
        align: 'center',
        dynamicTag: {
          function: stateRegionForTag,
          field: 'state_initials'
        },
      },
      {
        title: 'Cidade',
        name: 'city_name',
        orderFieldName: 'city.name',
        sortable: true,
        align: 'center',
        tags: [
          { 
            color: '#E2E8F0',
            textColor: '#1A202C'
          },
        ]
      },
      {
        title: 'Status',
        name: 'isActive',
        sortable: true,
        align: 'center',
        tags: [
          { 
            value: false, 
            text: 'Inativa',
            color: '#FEE2E2',
            textColor: '#7F1D1D'
          },
          { 
            value: true,
            text: 'Ativa',
            color: '#DCFCE7',
            textColor: '#171923'
          }
        ]
      },
      
    ] as IColumn[];

    const actions = [
      {
        text: 'Editar',
        icon: <Image src="/icons/table/edit.svg" width={5} height="auto" />,
        isVisible: (persona: IPersona): boolean => {return true},
        isDisabled: (persona: IPersona): boolean => { return !checkCollaboratorPermission(validate as IResponse, props.guid_client, 'persona.write') },
        action: (persona: IPersona) => {
          setCurrentPersona(persona);
          setOpenEditPersonaModal(true);
        },
      },
      {
        text: (persona: IPersona): string => { return persona.isActive ? 'Desativar' : 'Ativar'},
        icon: <Image src="/icons/table/minus.svg" width={5} height="auto" />,
        isVisible: (persona: IPersona): boolean => {return true},
        isDisabled: (persona: IPersona): boolean => {return !checkCollaboratorPermission(validate as IResponse, props.guid_client, 'persona.delete')},
        action: (persona: IPersona) => {
          handleToggleActivePerson(persona);
        }
        ,
      }
    ] as IAction[];

    return (<>
      <Flex direction="column" w="100%" p="0" flexGrow="1" flexShrink="1" mr="30px" mt="10px">
        <Flex flexDirection="row" justifyContent="space-between" borderBottom="1px" borderBottomColor="gray.300" pb={4} mb="15px">
          <Text fontSize="18px" mt={8} fontFamily="Poppins-Medium">Pessoas Físicas ({countPersonas})</Text>

          {!isLoading && checkCollaboratorPermission(validate as IResponse, props.guid_client, 'persona.write') ? (
            <Flex alignItems='center' gap={4}>
              <CreatePersonaModal guid_client={props.guid_client} flushHook={setRefreshData} />
            </Flex>
          ) : null}
        </Flex>

        <Filters
          filters={filterRowsCertificate}
          selectedValuesHook={setFilterDataSelected}
          clearFilters={clearFilters}
        />

        <Flex mt={12} direction="column">
          <Datatables<IPersona>
            name="Pessoas"
            columns={columns}
            actions={actions}
            metaDataHook={setMetaData}
            data={personaList}
            isLoading={isLoading}
          />
        </Flex>
      </Flex>

      {currentPersona &&
        <EditPersonaModal 
          guid_client={props.guid_client}
          guid_persona={currentPersona.guid_persona}
          flushHook={setRefreshData} 
          openModal={openEditPersonaModal}
          openModalHook={setOpenEditPersonaModal}
        />
      }
    </>)
}