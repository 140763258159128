
import { useEffect, useState } from "react";
import { Tooltip, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Text } from "@chakra-ui/react";
import { FormButton } from "../components/login/button.component";
interface IProps {
  callback: (location: string) => void;
  open: boolean;
  openHook: React.Dispatch<React.SetStateAction<boolean>>;
  location: string;
}

export const ModalContinueEditing = (props: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);

  const yesOption = async () => {
    setButtonDisabled(true);
    props.callback(props.location);
    setButtonDisabled(false);
    onClose();
  };

  useEffect(() => {
    if(props.open) {
      onOpen();
      props.openHook(false);
    }
  }, [props.open])

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} isCentered={true} onClose={onClose} size="sm">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Deseja realmente sair?</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Text>
                Você possui alterações que ainda não foram salvas
              </Text>

              <Flex gap={2} flexGrow={1} justifyContent="space-between">
                <FormButton onClick={yesOption} disabled={isButtonDisabled} width="50%">Sair mesmo assim</FormButton>
                <FormButton onClick={onClose} disabled={isButtonDisabled} width="50%">Cancelar</FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}