import { ArrowUpDownIcon } from "@chakra-ui/icons";
import { useDisclosure, useToast, Flex, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, TableContainer, Th, Thead, Tr, Tbody, Td, Checkbox, Button, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../../../components/loading";
import { InputFilter } from "../../../../../components/SelectFilter";
import { getClientCompanies } from "../../../../../_services/company.service";
import { IClientCompanies } from "../../../../../_services/interface/company.interface";
import { formatCnpj } from "../util/cnpj";

interface ISelectCompanyModalProps {
  guid_client: string;
  callback: (company: string[]) => void;
  width?: string | number;
  isMulti?: boolean;
  readOnly?: boolean;
  selected?: string[];
  showOnly?: string[];
  allCheckbox?: boolean;
}

export const SelectCompanyModal = (props: ISelectCompanyModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [filterSearch, setFilterSearch] = useState<string>("");
  const [alphaOrder, setAlphaOrder] = useState<boolean>(false);
  const [isLoading, setLoadingState] = useState<boolean>(true);
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<IClientCompanies[]>([]);
  const [companyDataSelected, setCompanySelected] = useState<IClientCompanies>();
  const [companyChecked, setCompanyChecked] = useState<string[]>(props.selected ? props.selected : []);
  const [companyRestriction, setCompanyRestriction] = useState<string[]>(props.showOnly ? props.showOnly : []);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    if(props.showOnly) {
      setCompanyRestriction(props.showOnly);
    }
  }, props.showOnly);

  const loadCompanies = async () => {
    if (props.guid_client) {
      setLoadingState(true);
      const { status, response } = await getClientCompanies(props.guid_client);
      if (status === 200) {
        //All companies reference
        setAllCompanies(response as IClientCompanies[]);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações dos usuários',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
      setLoadingState(false);
    }
  }

  useEffect(() => {
    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, props.selected]);

  useEffect(() => {
    if (filterSearch) {
      setFilteredCompanies(allCompanies.filter((e: IClientCompanies) =>
        e.companyCode?.toLocaleLowerCase().includes(filterSearch.toLocaleLowerCase())
        ||
        e.name?.toLocaleLowerCase().includes(filterSearch.toLocaleLowerCase())
        ||
        formatCnpj(e.cnpj).includes(filterSearch.toLocaleLowerCase())
      ))
    } else {
      setFilteredCompanies(allCompanies);
    }

    //Select company if companyCheck is marked
    if (!props.isMulti) {
      setCompanySelected(allCompanies.find((x) => x.guid_company === companyChecked[0]) as IClientCompanies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearch, allCompanies]);

  useEffect(() => {
    if (props.selected) {
      setCompanyChecked(props.selected);
    }
  }, [props.selected]);

  const sortCompanies = (type: string) => {
    const data = [...filteredCompanies];
    setAlphaOrder(!alphaOrder);

    if (type === "cod") {
      data.sort((a, b) => {
        if (alphaOrder) {
          return a.companyCode! < b.companyCode! ? -1 : a.companyCode! > b.companyCode! ? 1 : 0;
        } else {
          return a.companyCode! > b.companyCode! ? -1 : a.companyCode! < b.companyCode! ? 1 : 0;
        }
      });
    }

    if (type === "name") {
      data.sort((a, b) => {
        if (alphaOrder) {
          return a.name! < b.name! ? -1 : a.name! > b.name! ? 1 : 0;
        } else {
          return a.name! > b.name! ? -1 : a.name! < b.name! ? 1 : 0;
        }
      });
    }

    if (type === "cnpj") {
      data.sort((a, b) => {
        if (alphaOrder) {
          return a.cnpj! < b.cnpj! ? -1 : a.cnpj! > b.cnpj! ? 1 : 0;
        } else {
          return a.cnpj! > b.cnpj! ? -1 : a.cnpj! < b.cnpj! ? 1 : 0;
        }
      });
    }

    setFilteredCompanies(data);
  }

  const handleCollaboratorCheck = (e: { target: { value: string, checked: boolean } }) => {
    var updatedList = [...companyChecked];
    if (e.target.checked) {
      updatedList = [...companyChecked, e.target.value];
    } else {
      updatedList.splice(companyChecked.indexOf(e.target.value), 1);
    }
    setCompanyChecked(updatedList);
  }

  const selectUnique = (_comp: IClientCompanies) => {
    //Set the user
    props.callback([_comp.guid_company]);
    setCompanySelected(_comp);
    //Close the modal
    onClose();
  }

  const selectMulti = () => {
    //Set the user
    props.callback(companyChecked);
    //Close the modal
    onClose();
  }
  const handleCheckAll = (e: { target: { value: string, checked: boolean } }) => {
    const allCompanies = filteredCompanies
      .filter((x) => companyRestriction.length > 0 ? companyRestriction.includes(x.guid_company) : true)
      .map(item => item.guid_company)
    if (e.target.checked) {
      setCompanyChecked(allCompanies)
    } else {
      setCompanyChecked([])
    }
    setAllChecked(!allChecked)
  }

  return (
    <>
      {isLoading ? (
        <Flex border="1px solid" fontSize="12px" width="auto" w={props.width ? props.width : "100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
          Carregando Empresas
        </Flex>
      ) :
        props.readOnly ? (
          <Flex border="1px solid" fontSize="12px" width="auto" w={props.width ? props.width : "100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
            <Text color="#4B4EFF" fontWeight="bold">{companyChecked.length > 0 ? companyChecked.length > 1 ? `${companyChecked.length} empresas selecionadas` : `${companyChecked.length} empresa selecionada` : 'Selecione a Empresa'}</Text>
          </Flex>
        )
          :
          props.isMulti ? (
            <Flex border="1px solid" fontSize="12px" width="auto" w={props.width ? props.width : "100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
              <Link color="#4B4EFF" fontWeight="bold" onClick={onOpen}>{companyChecked.length > 0 ? companyChecked.length > 1 ? `${companyChecked.length} empresas selecionadas` : `${companyChecked.length} empresa selecionada` : 'Selecione a Empresa'}</Link>
            </Flex>
          ) : (
            <Flex flexDirection="column" alignItems="stretch" border="1px solid" fontSize="12px" width="auto" w={props.width ? props.width : "100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
              <Link display="flex" color="#4B4EFF" flexDirection="column" alignItems="stretch" fontWeight="bold" onClick={onOpen}>
                {companyDataSelected ? (
                  <Flex flexDirection="row" justifyContent="space-between">
                    <Text>{companyDataSelected.companyCode ? `(${companyDataSelected.companyCode}) ` : ''}{companyDataSelected.name}</Text>
                    <Text>{formatCnpj(companyDataSelected.cnpj)}</Text>
                  </Flex>
                ) : 'Selecione a Empresa'}
              </Link>
            </Flex>
          )
      }

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Selecionar Empresas</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12} mb={8}>
            <Flex flexDirection="column" gap={5}>
              {!isLoading ? (
                <Flex justifyContent="center" flexDirection="column" gap={5}>
                  <InputFilter value={filterSearch} onChange={(e: any) => setFilterSearch(e.target.value)} placeholder="Digite o nome, código ou CNPJ da empresa para buscar" />
                  <TableContainer fontFamily="Poppins-Light" overflowX="auto" overflowY="auto" h={400}>
                    <Table variant='striped' colorScheme='gray' fontSize="12px" fontFamily="Poppins-Medium">
                      <Thead>
                        <Tr>
                        <Th textAlign="center" width={50} textTransform="none" fontFamily="Poppins-Medium">
                              {props.allCheckbox ? (
                                <Checkbox size="md" colorScheme="green" isChecked={allChecked} onChange={handleCheckAll} value="1" />
                              ) : null
                              }
                            </Th>
                            <Th textAlign="center" width={50} textTransform="none" fontFamily="Poppins-Medium" onClick={() => sortCompanies('cod')} _hover={{ cursor: "pointer" }}>Cod <ArrowUpDownIcon /></Th>
                            <Th textAlign="center" width={100} textTransform="none" fontFamily="Poppins-Medium" onClick={() => sortCompanies('cnpj')} _hover={{ cursor: "pointer" }}>CNPJ <ArrowUpDownIcon /></Th>
                            <Th textTransform="none" fontFamily="Poppins-Medium" onClick={() => sortCompanies('name')} _hover={{ cursor: "pointer" }}>Nome <ArrowUpDownIcon /></Th>
                        </Tr>
                      </Thead>
                      <Tbody alignItems="center" justifyContent="center">
                        {filteredCompanies.filter((x) => companyRestriction.length > 0 ? companyRestriction.includes(x.guid_company) : true).map((_el, _key) => (
                          <Tr key={_key}>
                            
                            <Td textAlign="center">
                              {props.isMulti ? (
                                <Checkbox size="md" colorScheme="green" isChecked={companyChecked.includes(_el.guid_company)} onChange={handleCollaboratorCheck} value={_el.guid_company} />
                              ) : (
                                <Flex width="26px" alignItems="center" justifyContent="center" color="white" borderRadius={5} height="26px" resize="none" onClick={() => selectUnique(_el)} _hover={{ cursor: "pointer" }}>
                                  <Checkbox size="md" colorScheme="green" isChecked={companyChecked.includes(_el.guid_company)} value={_el.guid_company} />
                                </Flex>
                              )}
                            </Td>
                            <Td textAlign="center">{_el.companyCode ? _el.companyCode : '-'}</Td>
                            <Td textAlign="center">{formatCnpj(_el.cnpj)}</Td>
                            <Td>{_el.name}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              ) : (
                <LoadingComponent />
              )}
              {props.isMulti ? (
                <Flex direction="row" justifyContent="center" alignItems="stretch" gap={2}>
                  <Button bgColor="#4B4EFF" size="sm" width="70%" color="white" fontWeight="light" onClick={selectMulti} _hover={{ bg: '#282be0' }}>
                    Salvar alteração
                  </Button>
                </Flex>
              ) : null}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}