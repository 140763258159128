import { CircularProgress, CircularProgressLabel, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { ICNDListData } from "../../../../_services/interface/cnd.interface"
import { IWidgetIdent } from "../interfaces/IWidget";
import { calcPercent } from "../util/util";
import { WidgetBase } from "./WidgetBase"
import moment from 'moment';
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { ListAllCNdsCustomFilter } from "../../CNDManager/modals/listAllCNdsJurisdictionModal";
import { getCertificatesPropertyValuesRecursively } from "../../Certificate/Util/CertificateUtils";
import { IClientDataResponse } from "../../../../_services/interface/client.interface";
import { RoundShape } from "react-placeholder/lib/placeholders";
moment.updateLocale('pt', {
  months: [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
    "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ]
});

interface ICertPie extends IWidgetIdent {
  data?: ICNDListData[];
  isLoading?: boolean;
  currentClient?: IClientDataResponse;
  certificates?: ICertificate[];
  startDateFilter?: string;
  endDateFilter?: string;
}

interface ICndComplianceWidget {
  name: string;
  percent: number;
  slug: string;
  certificates: ICertificate[];
  currentClient?: IClientDataResponse;
  startDateFilter?: string;
  endDateFilter?: string;
  data?: ICNDListData[];
}

export const CndComplianceWidget = (props: ICndComplianceWidget) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const open = () => {
    onOpen();
  }

  return (
    <>
      <Flex flexDirection="column" cursor="pointer" onClick={open} justifyContent="center" gap={4}>
        <Text textAlign="center">{props.name}</Text>
        <CircularProgress value={props.percent} color="#4b4eff" size='150px' thickness='10px'>
          <CircularProgressLabel fontSize={14} fontWeight="bold">
            {props.percent}%<br/>Emitido
          </CircularProgressLabel>
        </CircularProgress>
      </Flex> 

      {props.certificates && props.currentClient && (
        <ListAllCNdsCustomFilter
          isOpen={isOpen}
          onClose={onClose}
          slug={props.slug}
          certificates={props.certificates}
          currentClient={props.currentClient}
          data={props.data as ICNDListData[]}
        />
      )}
    </>
  )
}

export const CertPie = (props: ICertPie) => {  
  const cndData = props.data ? props.data as ICNDListData[] : [];
  if (props.id && props.index !== undefined && props.currentClient) {
    const products = props.currentClient.products;
    return (
      <WidgetBase title={`Gerenciador de Certidões - Certidões por tipo`} id={props.id} index={props.index}>
        <Flex flexDirection="column" gap={4} width="100%" alignItems="center" justifyContent="center">
          <Flex flexWrap="wrap" display="flex" flexDirection="row" gap={4} width="100%" alignItems="center" justifyContent="center">
            {
              !props.isLoading ? (
                props.certificates?.map((x) => {

                  const availableSlug = getCertificatesPropertyValuesRecursively([x] as ICertificate[], 'slug' as keyof ICertificate);

                  const count = cndData.filter((y) => availableSlug[0].includes(y.slug) && y.slug_status === 'not-pendency').length;

                  return products[x.slug as string] && props.certificates && props.currentClient && (
                    <>
                      <CndComplianceWidget 
                        name={x.name as string} 
                        slug={x.slug as string} 
                        percent={calcPercent(count,cndData.filter((y) => availableSlug[0].includes(y.slug)).length)}
                        certificates={props.certificates}
                        currentClient={props.currentClient}
                        startDateFilter={props.startDateFilter}
                        endDateFilter={props.endDateFilter}
                        data={props.data}
                      />
                    </>
                  )
                })
              ) : (<>
                <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
                <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
                <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
              </>)
            }
          </Flex>
        </Flex>
        
      </WidgetBase>
    )
  } else {
    return (
      <WidgetBase title={`Gerenciador de Certidões - Certidões por tipo`} id="loading_cert_pie" index={0}>
        <Flex flexDirection="column" gap={4} width="100%" alignItems="center" justifyContent="center">
          <Flex flexWrap="wrap" display="flex" flexDirection="row" gap={4} width="100%" alignItems="center" justifyContent="center">
            {
              Array.from({ length: 10 }, (_, index) => (
                (<RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>)
              ))
            }
          </Flex>
        </Flex>
        
      </WidgetBase>
    )
  }
}