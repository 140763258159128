import { Flex, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { configTaxKanban, getConfigTaxKanban } from "../../../../_services/config.service";
import { BlockSwitch } from "../../../../components/BlockSwitch";

interface ITaxKanbanProps {
  refreshData: boolean;
  guid_client: string;
}

export const TaxKanban = (props: ITaxKanbanProps) => {
  const toast = useToast();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [isActiveKanban, setIsActiveKanban] = useState<boolean>(false);

  const submit = async () => {
    setSubmitDisabled(true);
    const {status, response} = await configTaxKanban(props.guid_client, {
      isActive: isActiveKanban
    });

    if(status === 200) {
      toast({
        title: 'Configuração Atualizada',
        description: 'Os dados foram atualizados com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });
    }

    if(status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });
    } else if (status === 400) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao atualizar as informações',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
    }

    setSubmitDisabled(false);
  }

  const configFields = async () => {
    const {status, response} = await getConfigTaxKanban(props.guid_client);

    if(
      status === 200 
      && response
      && 'isActive' in response
    ) {
      const { isActive } = response;
      setIsActiveKanban(isActive)
    }
  }

  useEffect(() => {
    configFields();
  }, [])

  useEffect(() => {
    if(props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  return (
    <Flex direction="column" w="100%">
      <Flex marginBottom="32px">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Tax Kanban</Text>
      </Flex>

      <Flex width="80%" flexDirection="column" gap="24px" marginBottom="35px">
        <BlockSwitch
          isActive={isActiveKanban}
          setIsActive={setIsActiveKanban}
          name="Tax Kanban"
          icon="/icons/Tax_kanban.png"
        />
      </Flex>

      <hr />

      <Flex width="100%" justifyContent="flex-start" marginTop="35px">
        <FormButton onClick={submit} disabled={submitDisabled}>
          Salvar alterações
        </FormButton>
      </Flex>
    </Flex>
  );
}
