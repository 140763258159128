import { Button, Flex, Radio, RadioGroup, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "jquery";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { TaxlyInput, TaxlyInputPassword } from "../../../../components/Input";
import { FormButton } from "../../../../components/login/button.component";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { configDisplay, getConfigDisplay } from "../../../../_services/config.service";

interface IDisplayProps {
  refreshData: boolean;
  guid_client: string;
}

export const Display = (props: IDisplayProps) => {
  const toast = useToast();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [certificateMenu, setCertificateMenu] = useState<'default'|'jurisdiction'>('default');
  const [displayLastValidCertificate, setDisplayLastValidCertificate] = useState<boolean>(false);
  const [customStatusForCertificate, setCustomStatusForCertificate] = useState<boolean>(false);

  const submit = async () => {
    setSubmitDisabled(true);
    const {status, response} = await configDisplay(props.guid_client,{
      certificateMenu,
      displayLastValidCertificate,
      customStatusForCertificate
    });

    if(status === 200) {
      toast({
        title: 'Configuração Atualizada',
        description: 'Os dados foram atualizados com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });
    }

    if(status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });
    } else if (status === 400) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao atualizar as informações',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
    }

    setSubmitDisabled(false);
  }

  const configFields = async () => {
    const {status, response} = await getConfigDisplay(props.guid_client);

    if(
      status === 200 
      && response
      && 'certificateMenu' in response
    ) {
      const {certificateMenu, displayLastValidCertificate, customStatusForCertificate} = response;

      setCertificateMenu(certificateMenu);
      setDisplayLastValidCertificate(displayLastValidCertificate);
      setCustomStatusForCertificate(customStatusForCertificate);
    }
  }

  useEffect(() => {
    configFields();
  }, [])

  useEffect(() => {
    if(props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  return (
    <Flex direction="column" w="100%">
      <Flex marginBottom="32px">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Certidões</Text>
      </Flex>

      <Flex width="80%" flexDirection="column" gap="24px">
        <Flex direction="column" gap={2} mb={4}>
          <Text fontSize="16px" mt={2} fontFamily="Poppins-Medium">Exibição de certidões no menu lateral</Text>
          <RadioGroup onChange={(value: 'default'|'jurisdiction') => setCertificateMenu(value)} value={certificateMenu}>
            <Flex direction="column" gap={2} pb={2}>
              <Radio value="default">Agrupado por tipo de certidão (CND, FGTS, CNDT, etc.)</Radio>
              <Radio value="jurisdiction">Agrupado por jurisdição de cada tipo de certidão (Federal, Estadual ou Municipal)</Radio>
            </Flex>
          </RadioGroup>
        </Flex>

        <Flex direction="column" gap={2} mb={4}>
          <Text fontSize="16px" mt={2} fontFamily="Poppins-Medium">Exibição das certidões</Text>
          <RadioGroup onChange={(value) => setDisplayLastValidCertificate(value === 'true')} value={displayLastValidCertificate ? 'true' : 'false'}>
            <Flex direction="column" gap={2} pb={2}>
              <Radio value="true">Exibir última certidão válida</Radio>
              <Radio value="false">Exibir última certidão encontrada</Radio>
            </Flex>
          </RadioGroup>
        </Flex>


        <Flex direction="column" gap={2} mb={4}>
          <Text fontSize="16px" mt={2} fontFamily="Poppins-Medium">Exibição de status das certidões</Text>
          <RadioGroup onChange={(value) => setCustomStatusForCertificate(value === 'true')} value={customStatusForCertificate ? 'true' : 'false'}>
            <Flex direction="column" gap={2} pb={2}>
              <Radio value="true">Exibir status individualizados</Radio>
              <Radio value="false">Exibir status válido / inválido</Radio>
            </Flex>
          </RadioGroup>
        </Flex>
      </Flex>

      <hr />

      <Flex width="100%" justifyContent="flex-start" marginTop="35px">
        <FormButton onClick={submit} disabled={submitDisabled}>
          Salvar alterações
        </FormButton>
      </Flex>
    </Flex>
  );
}
