import { Button, Flex, Radio, RadioGroup, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "jquery";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { TaxlyInput, TaxlyInputPassword } from "../../../../components/Input";
import { FormButton } from "../../../../components/login/button.component";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { configDisplay, getConfigDisplay } from "../../../../_services/config.service";

interface ITaxCurrentAccountProps {
  refreshData: boolean;
  guid_client: string;
}

export const TaxCurrentAccount = (props: ITaxCurrentAccountProps) => {
  const toast = useToast();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const submit = async () => {
    setSubmitDisabled(true);
    // const {status, response} = await configDisplay(props.guid_client,{
    //   certificateMenu,
    //   displayLastValidCertificate,
    //   customStatusForCertificate
    // });

    // if(status === 200) {
    //   toast({
    //     title: 'Configuração Atualizada',
    //     description: 'Os dados foram atualizados com sucesso',
    //     status: 'success',
    //     duration: 2000,
    //     isClosable: true
    //   });
    // }

    // if(status === 400 && response && 'message' in response) {
    //   toast({
    //     title: 'Erro ao atualizar',
    //     description: response.message,
    //     status: 'error',
    //     duration: 4000,
    //     isClosable: true
    //   });
    // } else if (status === 400) {
    //   toast({
    //     title: 'Erro ao atualizar',
    //     description: 'Ocorreu um erro ao atualizar as informações',
    //     status: 'error',
    //     duration: 4000,
    //     isClosable: true
    //   });
    // }

    setSubmitDisabled(false);
  }

  const configFields = async () => {
    const {status, response} = await getConfigDisplay(props.guid_client);

    if(
      status === 200 
      && response
      && 'certificateMenu' in response
    ) {
      const {certificateMenu, displayLastValidCertificate, customStatusForCertificate} = response;

    }
  }

  useEffect(() => {
    configFields();
  }, [])

  useEffect(() => {
    if(props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  return (
    <Flex direction="column" w="100%">
      <Flex marginBottom="32px">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Conta Corrente Fiscal (Em Breve)</Text>
      </Flex>

      <Flex width="80%" flexDirection="column" gap="24px">
        
      </Flex>

      <hr />

      {/* <Flex width="100%" justifyContent="flex-start" marginTop="35px">
        <FormButton onClick={submit} disabled={submitDisabled}>
          Salvar alterações
        </FormButton>
      </Flex> */}
    </Flex>
  );
}
