import { ApiCall, ApiAuth } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import { IRequestAccount, IRequestCompany, IRequestDisplay, IRequestTaxKanban, IResponseAccount, IResponseCompany, IResponseDisplay, IResponseTaxKanban } from "./interface/config.interface";

export const getConfigAccount = async (): Promise<TPremiseResponse<IResponseAccount>> => {
  return await new ApiCall(`/config/account`, ApiAuth()).get<IResponseAccount>();
};

export const configAccount = async (data: IRequestAccount): Promise<TPremiseResponse<IResponseAccount>> => {
  return await new ApiCall(`/config/account`, ApiAuth()).post<IResponseAccount, IRequestAccount>(data);
};

export const getConfigDisplay = async (guid_client: string): Promise<TPremiseResponse<IResponseDisplay>> => {
  return await new ApiCall(`/config/display/${guid_client}`, ApiAuth()).get<IResponseDisplay>();
};

export const configDisplay = async (guid_client: string, data: IRequestDisplay): Promise<TPremiseResponse<IResponseDisplay>> => {
  return await new ApiCall(`/config/display/${guid_client}`, ApiAuth()).post<IResponseDisplay, IRequestDisplay>(data);
};

export const getConfigCompany = async (guid_client: string): Promise<TPremiseResponse<IResponseCompany>> => {
  return await new ApiCall(`/config/company/${guid_client}`, ApiAuth()).get<IResponseCompany>();
};

export const configCompany = async (guid_client: string, data: IRequestCompany): Promise<TPremiseResponse<IResponseCompany>> => {
  return await new ApiCall(`/config/company/${guid_client}`, ApiAuth()).post<IResponseCompany, IRequestCompany>(data);
};

export const getConfigTaxKanban = async (guid_client: string): Promise<TPremiseResponse<IResponseTaxKanban>> => {
  return await new ApiCall(`/config/tax-kanban/${guid_client}`, ApiAuth()).get<IResponseTaxKanban>();
};

export const configTaxKanban = async (guid_client: string, data: IRequestTaxKanban): Promise<TPremiseResponse<IResponseTaxKanban>> => {
  return await new ApiCall(`/config/tax-kanban/${guid_client}`, ApiAuth()).post<IResponseTaxKanban, IRequestTaxKanban>(data);
};