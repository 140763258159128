import { Flex, useToast, Text } from '@chakra-ui/react'
import { useEffect, useState } from "react";
import { FilterDataSelected, Filters, FilterTypeEnum } from '../../../../../../components/Filters';
import { Datatables } from '../../../../../../components/Table/Datatables';
import { IData, IDataMeta, ITableMetaData } from '../../../../../../components/Table/table.interface';
import { getClientCompanies } from '../../../../../../_services/company.service';
import { IClientCompanies } from '../../../../../../_services/interface/company.interface';
import { filterCompanies, sortCompanies } from '../Util/DataManager';
import { columns, filterRows } from '../Util/FiltersAndColumns';

interface ICompanyFilterAndTable {
  guid_client: string;
  error: boolean;
  setSelectedCompanies: React.Dispatch<React.SetStateAction<string[]>>
}

export const CompanyFilterAndTable = (props: ICompanyFilterAndTable) => {
  const toast = useToast();
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);
  const [reinitFilter, setReinitFilter] = useState<boolean>(false);

  //Company Data
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredCompanies, setFilteredCompanies] = useState<IData<any>|null>(null);
  const [companyChecked, setCompanyChecked] = useState<string[]>([]);
  const [companyMetaData, setCompanyMetaData] = useState<ITableMetaData|undefined>();
  const [companies, setCompanies] = useState<IClientCompanies[]>([]);

  const fetchCompany = async () => {
    if (props.guid_client) {
      setLoading(true);

      const { status, response } = await getClientCompanies(props.guid_client, true);
      if (status === 200) {
        if (response !== undefined) {
          setCompanies(response as IClientCompanies[]);
        }
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das empresas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }

  const selectAll = () => {
    if(filterDataSelected) {
      const filteredCompanies = filterCompanies(companies, filterDataSelected, companyChecked);

      if (companyChecked.length === filteredCompanies.filter((company) => company.isActive).length && companyChecked.length > 0) {
        setCompanyChecked([]);
      } else {
        setCompanyChecked(filteredCompanies.filter((company) => company.isActive).map((x): string => x.guid_company));
      }
    }
  }

  useEffect(() => {
    if (companyMetaData && companies && filterDataSelected) {
      const filteredCompanies = filterCompanies(companies, filterDataSelected, companyChecked);
      const orderedCompanies = sortCompanies(companyMetaData, filteredCompanies, companyChecked);

      const { currentPage, totalPages, rowByPage } = companyMetaData;
      
      const data = orderedCompanies;
      const start = (currentPage - 1) * rowByPage;
      const end = start + rowByPage;
      const paginatedData = data.slice(start, end);

      const meta: IDataMeta = {
        currentPage,
        isFirstPage: currentPage === 1,
        isLastPage: currentPage === totalPages,
        nextPage: currentPage === totalPages ? null : currentPage + 1,
        pageCount: Math.ceil(orderedCompanies.length / rowByPage),
        previousPage: currentPage === 1 ? null : currentPage - 1,
        totalCount: orderedCompanies.length
      }

      setFilteredCompanies({
        data: paginatedData,
        meta
      });
    }
  }, [companyMetaData, companies, filterDataSelected]);

  useEffect(() => {
    props.setSelectedCompanies(companyChecked);
  }, [companyChecked])

  useEffect(() => {
    setReinitFilter(true);
    fetchCompany();
  }, [props.guid_client]);

  useEffect(() => {
    setTimeout(() => {
      setReinitFilter(false);
    }, 1000);
  }, [reinitFilter])

  return (
    <>
      <Filters
        filters={filterRows}
        selectedValuesHook={setFilterDataSelected}
        reinitFilter={reinitFilter}
        allCompanies={true}
      />

      {
        props.error && (
          <Text color="red" fontSize={"12px"}>
            Selecione ao menos 1 empresa
          </Text>
        )
      }

      <Flex
        alignItems="stretch"
        direction="column"
        gap={2}
        flexGrow={1}
        mt={8}
        mb={4}
      >
        {
          filterDataSelected
          && (
            filterDataSelected[FilterTypeEnum.companyGroupFilter].length > 0
            || filterDataSelected[FilterTypeEnum.companyFilter].length > 0
          ) && (
            <Datatables
              name="Empresas"
              columns={columns}
              metaDataHook={setCompanyMetaData}
              data={filteredCompanies}
              isLoading={loading}
              showTotalRows={true}
              maxHeigth="400px"
              checkBox={{
                selecteds: companyChecked,
                setSelecteds: setCompanyChecked,
                checkAll: selectAll,
                key: 'guid_company'
              }}
            />
          )
        }
      </Flex>
    </>
  );
};
