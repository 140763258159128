import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "jquery";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { TaxlyInput, TaxlyInputPassword } from "../../../../components/Input";
import { FormButton } from "../../../../components/login/button.component";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { configAccount, getConfigAccount } from "../../../../_services/config.service";

interface IAccountProps {
  refreshData: boolean
}

interface IAccountForm {
  name: string
  email: string,
  phone?: string|null,
  position?: string|null,
  currentPassword?: string,
  newPassword?: string,
  confirmNewPassword?: string,
}

export const Account = (props: IAccountProps) => {
  const toast = useToast();

  const YupCompanyChangeForm = Yup.object().shape({
    name: Yup.string().required('Nome é obriatório'),
    email: Yup.string().required('E-mail é obriatório'),
    phone: Yup.string().optional().nullable(),
    position: Yup.string().optional().nullable(),
    currentPassword: Yup.string().optional(),
    newPassword: Yup.string().when('currentPassword', {
      is: (currentPassword: string | undefined) => !!currentPassword,
      then: Yup.string().required('Nova senha é obrigatória').min(6, 'A senha precisa ter pelo menos 6 caracteres'),
    }),
    confirmNewPassword: Yup.string().when('newPassword', {
      is: (newPassword: string | undefined) => !!newPassword,
      then: Yup.string()
        .required('Confirmação de nova senha é obrigatória')
        .oneOf([Yup.ref('newPassword')], 'As senhas precisam ser iguais'),
    }),
  });

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<IAccountForm>({ resolver: yupResolver(YupCompanyChangeForm) });

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const handleFormInfo = async (data: IAccountForm) => {
    setSubmitDisabled(true);
    const {status, response} = await configAccount({
      name: data.name,
      email: data.email,
      phone: data.phone ?? null,
      position: data.position ?? null,
      currentPassword: data.currentPassword ?? null,
      newPassword: data.newPassword ?? null,
      confirmNewPassword: data.confirmNewPassword ?? null,
    });

    if(status === 200) {
      toast({
        title: 'Configuração Atualizada',
        description: 'Os dados foram atualizados com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true
      });
    }

    if(status === 400 && response && 'message' in response) {
      toast({
        title: 'Erro ao atualizar',
        description: response.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });
    } else if (status === 400) {
      toast({
        title: 'Erro ao atualizar',
        description: 'Ocorreu um erro ao atualizar as informações',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
    }
    
    setSubmitDisabled(false);
  }

  const configFields = async () => {
    const {status, response} = await getConfigAccount();

    if(
      status === 200 
      && response
      && 'name' in response
    ) {
      const {name, email, phone, position} = response;
      setValue('name', name);
      setValue('email', email);
      setValue('phone', phone);
      setValue('position', position);
    }
  }

  useEffect(() => {
    configFields();
  }, [])

  useEffect(() => {
    if(props.refreshData) {
      configFields();
    }
  }, [props.refreshData]);

  return (
    <Flex direction="column" w="100%">
      <Flex marginBottom="32px">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Minha conta</Text>
      </Flex>

      <Flex width="80%" flexDirection="column" gap="24px">
        <Flex w="100%" flexDirection="column">
          <TaxlyInput
            w="100%"
            placeholder="Nome Completo"
            label={'Nome Completo'}
            {...register("name")}
          />
          {errors.name && <Text color="red.500" fontSize="sm">{errors.name.message}</Text>}
        </Flex>

        <Flex w="100%" flexDirection="column">
          <TaxlyInput
            w="100%"
            placeholder="E-mail"
            label={'E-mail'}
            {...register("email")}
          />
          {errors.email && <Text color="red.500" fontSize="sm">{errors.email.message}</Text>}
        </Flex>

        <Flex gap="15px">
          <Flex w="50%" flexDirection="column">
            <TaxlyInput
              w="100%"
              placeholder="Telefone"
              label={'Telefone'}
              {...register("phone")}
              mask="(99) 99999-9999"
              />
            {errors.phone && <Text color="red.500" fontSize="sm">{errors.phone.message}</Text>}
          </Flex>

          <Flex w="50%" flexDirection="column">
            <TaxlyInput
              w="100%"
              placeholder="Cargo"
              label={'Cargo'}
              {...register("position")}
            />
            {errors.position && <Text color="red.500" fontSize="sm">{errors.position.message}</Text>}
          </Flex>
        </Flex>
      </Flex>

      <Flex marginBottom="32px" marginTop="32px">
        <Text fontSize="18px" fontFamily="Poppins-Medium" fontWeight="500">Alterar senha</Text>
      </Flex>

      <Flex width="80%" flexDirection="column" gap="24px">
        <Flex w="100%" flexDirection="column">
          <TaxlyInputPassword
            w="100%"
            placeholder="Digite sua senha atual"
            label={'Senha Atual'}
            {...register("currentPassword")}
          />
          {errors.currentPassword && <Text color="red.500" fontSize="sm">{errors.currentPassword.message}</Text>}
        </Flex>

        <Flex gap="15px">
          <Flex w="50%" flexDirection="column">
            <TaxlyInputPassword
              w="100%"
              placeholder="Digite a nova senha"
              label={'Nova senha'}
              {...register("newPassword")}
            />
            {errors.newPassword && <Text color="red.500" fontSize="sm">{errors.newPassword.message}</Text>}
          </Flex>

          <Flex w="50%" flexDirection="column">
            <TaxlyInputPassword
              w="100%"
              placeholder="Confirme a nova senha"
              label={'Confirme sua nova senha'}
              {...register("confirmNewPassword")}
            />
            {errors.confirmNewPassword && <Text color="red.500" fontSize="sm">{errors.confirmNewPassword.message}</Text>}
          </Flex>
        </Flex>

        <Text color="#718096" fontFamily="Poppins-Medium" fontSize="12px">
          A senha precisa ter pelo menos 6 caracteres dentre eles letras maísculas, minusculas, números e simbolos.
        </Text>

        <hr />

        <Flex width="100%" justifyContent="flex-start">
          <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>
            Salvar alterações
          </FormButton>
        </Flex>
      </Flex>
    </Flex>
  );
}
