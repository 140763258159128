export const mentionStyle = {
  color: '#4B4EFF',
  fontFamily: 'Poppins-medium',
  fontWeight: 500,
  fontSize: 12,
  zIndex: 99999,
  position: 'relative'
};

export const mentionsInputStyles = {
  control: {
    backgroundColor: '#fff',
    fontSize: 12,
    fontFamily: 'Poppins-medium',
    fontWeight: 500,
  },
  width: '100%',
  height: '100%',
  border: '1px solid #ffffff',
  '&multiLine': {
    control: {
      fontFamily: 'Poppins-medium',
      fontWeight: 500,
      fontSize: 12,
      border: '1px solid #ffffff',
      minHeight: 63,
    },
    highlighter: {
      padding: 8,
      border: '1px solid #ffffff',
    },
    input: {
      padding: 9,
      border: '1px solid #ffffff',
      outline: 'none',
      resize: 'none',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
      outline: 'none',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid #EDF2F7',
      fontFamily: 'Poppins-medium',
      fontWeight: 500,
      fontSize: 12,
      maxHeight: "155px",
      overflow: "auto",
      borderRadius: "6px",
      zIndex: 9999999,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid #EDF2F7',
      color:"#171923",
      fontFamily:"Poppins-medium",
      minWidth: "300px",
      height: "33px",
      '&focused': {
        backgroundColor: '#F7FAFC',
      },
    },
  },
}