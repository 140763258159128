import { Flex, Image, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react";
import 'moment/locale/pt-br';
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { IResponse } from "../../../../_services/interface/user.interface";
import { listCompanyGroup } from "../../../../_services/companyGroup.service";
import { IFilterListCompanyGroup } from "../../../../_services/interface/companyGroup.interface";
import { checkCollaboratorPermission } from "../../Users/utils/checkPermission";
import { CreateEditCompanyGroupModal } from "./modals/CreateEditCompanyGroupModal";
import { DesativarCompanyGroupModal } from "./modals/DesativarCompanyGroupModal";
import { FilterDataSelected, FilterRow, FilterTypeEnum, Filters } from "../../../../components/Filters";
import { IAction, IColumn, IData, ITableMetaData } from "../../../../components/Table/table.interface";
import { Datatables } from "../../../../components/Table/Datatables";
import { listCompanyWithoutPermission } from "../../../../_services/company.service";
import { IClientCompanies, IFilterListCompany, ICompany } from "../../../../_services/interface/company.interface";
import { stateRegionForTag } from "../../../../components/Table/utils";
import { EditCompanyModal } from "../Company/modals/EditCompanyModal";
import { RemoveCompanyGroupModal } from "./modals/RemoveCompanyGroupModal";

interface ICompanyGroupPanelProps {
  guid_client: string;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  totalCompaniesGroupHook: React.Dispatch<React.SetStateAction<number>>;
  refresh: boolean;
}

export const CompanyGroupPanel = (props: ICompanyGroupPanelProps) => {

  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [isLoadingSubTable, setLoadingStateSubTable] = useState<boolean>(false);
  const { validate } = useLoginFormStore();
  const toast = useToast();
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);
  const [groupsList, setGroupsList] = useState<IData<any>|null>(null);
  const [companyList, setCompanyList] = useState<IData<any>|null>(null);
  const [currentGroup, setCurrentGroup] = useState<any|undefined>(undefined);
  const [companyGroupMetaData, setCompanyGroupMetaData] = useState<ITableMetaData|undefined>();
  const [companyMetaData, setCompanyMetaData] = useState<ITableMetaData|undefined>();
  const [currentRowSubTable, setCurrentRowSubTable] = useState<any|undefined>(undefined);
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  
  const [currentCompany, setCurrentCompany] = useState<ICompany>();
  const [openEditCompanyModal, setOpenEditCompanyModal] = useState<boolean>(false);
  const [openRemoveCompanyFromGroupModal, setOpenRemoveCompanyFromGroupModal] = useState<boolean>(false);
  const [refreshSubTable, setRefreshSubTable] = useState<boolean>(false);

  const [openEditCompanyGroupModal, setOpenEditCompanyGroupModal] = useState<boolean>(false);
  const [openActiveCompanyGroupModal, setOpenActiveCompanyGroupModal] = useState<boolean>(false);

  const getFilter = (): IFilterListCompanyGroup => {

    const validFilterField = (field: string | string[] | undefined): boolean => {
      if (field) {
        if (typeof field === 'string') {
          if (field.trim() !== '') {
            return true;
          }
        } else if (field instanceof Array) {
          if (field.length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    const companies: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyFilter] : [];
    const companyGroups: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyGroupFilter] : [];

    const filters: IFilterListCompanyGroup = {
      page: companyGroupMetaData?.currentPage ?? 1,
      row_by_page: companyGroupMetaData?.rowByPage ?? -1,
      order_field: companyGroupMetaData?.orderField ?? 'createdAt',
      order_direction: companyGroupMetaData?.orderDirection ?? 'desc',
      ...(validFilterField(companies)   ? { companies: companies.join(',') } : {}),
      ...(validFilterField(companyGroups)   ? { groups: companyGroups.join(',') } : {}),
    };

    return filters;
  }

  const toastError = () => {
    toast({
      title: 'Ocorreu um erro',
      description: 'Ocorreu um erro ao tentar puxar as informações dos grupos de empresa',
      status: 'error',
      duration: 5000,
      isClosable: true
    });
  }

  const fetchListCompany = async (row: any) => {
    if (props.guid_client) {
      setCurrentRowSubTable(row);
      setLoadingStateSubTable(true);
  
      const filters: IFilterListCompany = {
        page: companyMetaData?.currentPage ?? 1,
        row_by_page: companyMetaData?.rowByPage ?? -1,
        order_field: companyMetaData?.orderField ?? 'createdAt',
        order_direction: companyMetaData?.orderDirection ?? 'desc',
        company: row.companies.map((company: any) => company.guid_company).join(','),
      };

      const { status, response } = await listCompanyWithoutPermission(props.guid_client, filters);
      if (status === 200) {
        if (response.meta.pageCount !== undefined && response.data !== undefined) {          
          setCompanyList(response);
        }
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das empresas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });

      }
      setTimeout(() => {
        setLoadingStateSubTable(false);
      }, 1000);
    }
  }

  const fetchListCompanyGroup = async () => {
    if (props.guid_client) {
      setLoadingState(true);

      const filters = getFilter();
      const { status, response } = await listCompanyGroup(props.guid_client, filters);

      if (status === 200) {

        if (response.meta.pageCount !== undefined && response.data !== undefined) {
          setGroupsList(response)
          props.totalCompaniesGroupHook(response.meta.totalCount);
        }
      } else {
        toastError();
      }
      setLoadingState(false);
    }
  }

  useEffect(() => {
    if(currentRowSubTable) {
      fetchListCompany(currentRowSubTable);
    }
  }, [companyMetaData]);

  useEffect(() => {
    if(currentRowSubTable && refreshSubTable) {
      fetchListCompany(currentRowSubTable);
      setRefreshSubTable(false);
    }
  }, [refreshSubTable]);

  useEffect(() => {
    setRefreshData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.guid_client, companyGroupMetaData, filterDataSelected]);

  useEffect(() => {
    if(props.refresh) {
      setRefreshData(true);
      props.setRefresh(false);
    }
  }, [props.refresh])

  useEffect(() => {
    if (refreshData) {
      fetchListCompanyGroup();
      setRefreshData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);


  useEffect(() => {
    if (clearFilters) {
      setClearFilters(false);
    }
  }, [clearFilters]);

  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.companyFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.companyGroupFilter,
          position: 2,
          size: 6,
        },
      ],
      position: 1
    },
  ] as FilterRow[];

  const columnsSubTable = [
    {
      title: 'Empresa - cód',
      name: 'nameWithCode',
      orderFieldName: 'name',
      sortable: true,
      align: 'left',
      elipse: {
        columnName: 'isScheduled',
        tooltipTextTrue: 'Agendada a pesquisa de certidões',
        tooltipTextFalse: 'Não agendada a pesquisa de certidões',
        validation: (isScheduled: boolean) => {
          return isScheduled;
        },
        canShow: (isForeign: boolean) => {
          return !isForeign;
        }
      }
    },
    {
      title: 'CNPJ',
      name: 'cnpj_formatted',
      orderFieldName: 'cnpj',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Tipo',
      name: 'type',
      sortable: true,
      align: 'center',
      tags: [
        { 
          value: 0, 
          text: 'Matriz',
          color: '#ECFCCB',
          textColor: '#365314'
        },
        { 
          value: 1,
          text: 'Filial',
          color: '#C4F1F9',
          textColor: '#065666'
        }
      ]
    },
    {
      title: 'Inscrição Estadual',
      name: 'stateReg',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Grupo',
      name: 'grupoLabel',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Estado',
      name: 'initials',
      orderFieldName: 'city.state.initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city',
      orderFieldName: 'city.name',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
    {
      title: 'Status',
      name: 'isActive',
      sortable: true,
      align: 'center',
      tags: [
        { 
          value: true, 
          text: 'Ativa',
          color: '#DCFCE7',
          textColor: '#171923'
        },
        { 
          value: false,
          text: 'Inativa',
          color: '#FEE2E2',
          textColor: '#7F1D1D'
        }
      ]
    },
  ] as IColumn[];

  const columns = [
    {
      title: 'Nome',
      name: 'name',
      sortable: false,
      align: 'left',
      width: '90%',
      subTableNoIcon: (row: any) => {return true},
    },
    {
      title: 'Status',
      name: 'isActive',
      sortable: false,
      align: 'center',
      width: '7%',
      subTableNoIcon: (row: any) => {return true},
      tags: [
        { 
          value: true, 
          text: 'Ativa',
          color: '#DCFCE7',
          textColor: '#171923'
        },
        { 
          value: false,
          text: 'Inativa',
          color: '#FEE2E2',
          textColor: '#7F1D1D'
        }
      ]
    },
    {
      title: 'Empresas',
      name: 'display_count',
      sortable: false,
      align: 'center',
      width: '3%',
      subTable: (row: any) => {return true},
    }
  ] as IColumn[];

  const actions = [
    {
      text: 'Editar',
      icon: <Image src="/icons/table/edit.svg" width={5} height="auto" />,
      isVisible: (companyGroup: any): boolean => {return true},
      isDisabled: (companyGroup: any): boolean => {return !checkCollaboratorPermission(validate as IResponse, props.guid_client, 'company.write')},
      action: (companyGroup: any) => {
        setCurrentGroup(companyGroup);
        setOpenEditCompanyGroupModal(true);
      }
    },
    {
      text: (companyGroup: any): string => { return companyGroup.isActive ? 'Desativar' : 'Ativar'},
      icon: <Image src="/icons/table/minus.svg" width={5} height="auto" />,
      isVisible: (companyGroup: any): boolean => {return true},
      isDisabled: (companyGroup: any): boolean => {return !checkCollaboratorPermission(validate as IResponse, props.guid_client, 'company.delete')},
      action: (companyGroup: any) => {
        setCurrentGroup(companyGroup);
        setOpenActiveCompanyGroupModal(true);
      }
    },
  ] as IAction[];

  const actionsSubTable = [
    {
      text: 'Editar',
      icon: <Image src="/icons/table/edit.svg" width={5} height="auto" />,
      isVisible: (company: ICompany): boolean => {return !company.isForeign},
      isDisabled: (company: ICompany): boolean => {return !checkCollaboratorPermission(validate as IResponse, props.guid_client, 'company.write')},
      action: (company: ICompany) => {
        setCurrentCompany(company);
        setOpenEditCompanyModal(true);
      },
    },
    {
      text: 'Remover do grupo',
      icon: <Image src="/icons/table/minus.svg" width={5} height="auto" />,
      isVisible: (company: ICompany): boolean => {return true},
      isDisabled: (company: ICompany): boolean => {return !checkCollaboratorPermission(validate as IResponse, props.guid_client, 'company.write')},
      action: (company: ICompany, group: any) => {
        setCurrentCompany(company);
        setCurrentGroup(group);
        setOpenRemoveCompanyFromGroupModal(true);
      },
    }
  ] as IAction[];

  return (<>
    <Flex direction="column" w="100%" p="0" flexGrow="1" flexShrink="1" mr="30px" mt="10px">
      <Flex flexDirection="row" justifyContent="flex-end" borderBottom="1px" borderBottomColor="gray.300" pb={4}>
        {!isLoading && checkCollaboratorPermission(validate as IResponse, props.guid_client as string, 'company.write') ? (
          <Flex alignItems='center' gap={4}>
            <CreateEditCompanyGroupModal guid_client={props.guid_client} flushHook={setRefreshData}/>
          </Flex>
        ) : null}
      </Flex>

      <Flex>
        <Filters
          filters={filterRows}
          selectedValuesHook={setFilterDataSelected}
        />
      </Flex>

      <Flex mt={10} direction="column">
        <Datatables
          name="Grupo de empresas"
          showHeader={false}
          columns={columns}
          actions={actions}
          data={groupsList}
          isLoading={isLoading}
          metaDataHook={setCompanyGroupMetaData}
          refreshSubTableHook={fetchListCompany}
        >
          <Datatables
            name="Empresas"
            columns={columnsSubTable}
            actions={actionsSubTable}
            data={companyList}
            isLoading={isLoadingSubTable}
            metaDataHook={setCompanyMetaData}
            pagination={false}
            rowByPage={false}
          />
        </Datatables>
      </Flex>
    </Flex>

    {currentGroup && (<>
      <CreateEditCompanyGroupModal 
        guid_client={props.guid_client} 
        guid_company_group={currentGroup.guid_company_group} 
        flushHook={setRefreshData}
        openModal={openEditCompanyGroupModal}
        openModalHook={setOpenEditCompanyGroupModal}
      />
      <DesativarCompanyGroupModal 
        guid_client={props.guid_client} 
        group={currentGroup} 
        flushHook={setRefreshData}
        openModal={openActiveCompanyGroupModal}
        openModalHook={setOpenActiveCompanyGroupModal}
      />
    </>)}

    {currentGroup && currentCompany && (<>
      <RemoveCompanyGroupModal 
        guid_client={props.guid_client} 
        group={currentGroup} 
        company={currentCompany} 
        flushHook={setRefreshData}
        openModal={openRemoveCompanyFromGroupModal}
        openModalHook={setOpenRemoveCompanyFromGroupModal}
      />
    </>)}

    <EditCompanyModal 
      guid_client={props.guid_client}
      company={currentCompany}
      flushHook={setRefreshSubTable}
      openModal={openEditCompanyModal}
      openModalHook={setOpenEditCompanyModal}
    />
  </>)
}