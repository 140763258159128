import { FilterRow, FilterTypeEnum } from "../../../../components/Filters";

export const taxCurrentAccountFilter = [
  {
    rowFields: [
      {
        name: FilterTypeEnum.startCreatedAtFilter,
        position: 1,
        size: 6,
      },
      {
        name: FilterTypeEnum.endCreatedAtFilter,
        position: 2,
        size: 6,
      },
    ],
    position: 1
  },
  {
    rowFields: [
      {
        name: FilterTypeEnum.companyFilter,
        position: 1,
        size: 6,
      },
      {
        name: FilterTypeEnum.companyGroupFilter,
        position: 2,
        size: 2,
      },
      {
        name: FilterTypeEnum.companyTypeFilter,
        position: 3,
        size: 2,
      },
      {
        name: FilterTypeEnum.companyCodeFilter,
        position: 4,
        size: 2,
      },
    ],
    position: 2,
  },
  {
    rowFields: [
      {
        name: FilterTypeEnum.taxCurrentAccountTypeFilter,
        position: 1,
        size: 2,
      },
      {
        name: FilterTypeEnum.taxCurrentAccountStatusFilter,
        position: 2,
        size: 2,
      },
      {
        name: FilterTypeEnum.jurisdictionFilter,
        position: 3,
        size: 2,
      },
      {
        name: FilterTypeEnum.taxCurrentAccountStateFilter,
        position: 4,
        size: 3,
      },
      {
        name: FilterTypeEnum.taxCurrentAccountCityFilter,
        position: 5,
        size: 3,
      },
    ],
    position: 3,
  }
] as FilterRow[];
