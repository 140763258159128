import { Flex, Image, Text } from "@chakra-ui/react";
import { useState } from "react";

interface IPaginationItemProps {
  page: number;
  selected: boolean;
  before?: string;
  after?: string;
  onClick: (page: number) => void;
}

const PaginationItem = (props: IPaginationItemProps) => {
  if (props.selected) {
    return (
      <>
        { props.before && (
            <Text letterSpacing={2}>{ props.before }</Text>
        )}

        <Flex
          onClick={() => props.onClick(props.page)}
          w={25}
          h={25}
          justifyContent="center"
          alignItems="center"
          fontWeight="bold"
          fontSize="12px"
          bg="#4B4EFF"
          color="white"
          borderRadius={5}
          _hover={{ bg: "#2d30c6", color: "white", cursor: "pointer", transition: "300ms" }}
        >
          {props.page}
        </Flex>

        { props.after && (
            <Text letterSpacing={2}>{ props.after }</Text>
        )}
      </>
    );
  } else {
    return (
      <>
        { props.before && (
            <Text letterSpacing={2}>{ props.before }</Text>
        )}

        <Flex
          border="1px solid"
          onClick={() => props.onClick(props.page)}
          borderColor="gray.400"
          w={25} h={25}
          justifyContent="center"
          alignItems="center"
          fontWeight="bold"
          fontSize="12px"
          borderRadius={5}
          _hover={{
            bg: "#383cf7",
            color: "white",
            cursor: "pointer",
            transition: "300ms",
            border: "none"
          }}
        >
          {props.page}
        </Flex>

        { props.after && (
            <Text letterSpacing={2}>{ props.after }</Text>
        )}
      </>
    );
  }
}

interface CustomStyleProps {
  [key: string]: any;
}

interface IPaginationProps {
  totalPages: number;
  currentPage: number;
  pageCallback: (page: number) => void;
  customStyle?: CustomStyleProps,
}

export const Pagination = (props: IPaginationProps) => {
  const slice = 4;
  const snap = 2;

  const pageControl = (pg: number) => {
    props.pageCallback(pg);
  }

  let showClosing = false;
  const pages = [];

  if (props.totalPages > 0) {
    if (props.totalPages > slice) {
      showClosing = true;

      if (props.currentPage < slice) {
        for (let i = 0; i < slice; i++) {
          pages.push(
              <PaginationItem
                  selected={props.currentPage === (i+1)}
                  onClick={pageControl}
                  key={i}
                  page={i+1}
              />
          );
        }
      } else if (props.currentPage >= slice && props.currentPage < (props.totalPages - slice)) {
        for (let i = props.currentPage - snap; i < props.currentPage + (snap + 1); i++) {
          pages.push(
              <PaginationItem
                  selected={props.currentPage === (i)}
                  onClick={pageControl}
                  key={i}
                  page={i}
              />
          );
        }
      } else if (props.currentPage >= (props.totalPages - slice)) {
        showClosing = false;
        if (props.currentPage === (props.totalPages - slice)) {
          pages.push(
              <PaginationItem
                  selected={props.currentPage === (props.currentPage - 1)}
                  onClick={pageControl} key={props.currentPage - 1}
                  page={props.currentPage - 1}
              />
          );
        }

        for (let i = (props.totalPages - slice); i < (props.totalPages + 1); i++) {
          pages.push(
              <PaginationItem
                  selected={props.currentPage === (i)}
                  onClick={pageControl}
                  key={i}
                  page={i}
              />
          );
        }
      }
    } else {
      for (let i = 0; i < props.totalPages; i++) {
        pages.push(
            <PaginationItem
                selected={props.currentPage === (i+1)}
                onClick={pageControl}
                key={i}
                page={i+1} />
        );
      }
    }

    if (props.totalPages < props.currentPage) {
      props.pageCallback(1);
    }

    if (props.currentPage >= slice) {
      const indexToRemove = pages.findIndex((page) => page.props.page === 1);
      if (indexToRemove !== -1) {
        pages.splice(indexToRemove, 1);
      }
    }
  }

  const closingPagination = () => {
    if (showClosing) {
      return (<PaginationItem selected={false} onClick={() => pageControl(props.totalPages)} page={props.totalPages} before="..." />);
    }
  }

  const goBackPage = () => {
    if(props.currentPage > 1) {
      pageControl(props.currentPage-1);
    }
  }

  const goForwardPage = () => {
    if (props.currentPage < props.totalPages) {
      pageControl(props.currentPage+1);
    }
  }

  return (
    <Flex className="pagination" gap={2} justifyContent="flex-start" p={props.customStyle?.padding ?? 4}>
      { props.currentPage > 1 && (
        <Flex
          onClick={() => goBackPage()}
          border="1px solid"
          borderColor="gray.400"
          w={25}
          h={25}
          justifyContent="center"
          alignItems="center"
          fontWeight="bold"
          fontSize="12px"
          borderRadius={5}
          _hover={{
            bg: "#383cf7",
            color: "white",
            cursor: "pointer",
            transition: "300ms",
            border: "none"
          }}
        >
          <Image width="6px" src="/icons/arrow-left-black.svg" />
        </Flex>
      )}

      {props.currentPage >= slice && (
          <PaginationItem
              selected={false}
              onClick={() => pageControl(1)}
              page={1}
              after="..."
          />
      )}

      {pages}

      {closingPagination()}

      { props.currentPage < props.totalPages && (
          <Flex
            onClick={() => goForwardPage()}
            border="1px solid"
            borderColor="gray.400"
            w={25} h={25}
            justifyContent="center"
            alignItems="center"
            fontWeight="bold"
            fontSize="12px"
            borderRadius={5}
            _hover={{
              bg: "#383cf7",
              color: "white",
              cursor: "pointer",
              transition: "300ms",
              border: "none"
            }}
          >
          <Image width="6px" src="/icons/arrow-right-black.svg" />
        </Flex>
      )}
    </Flex>
  );
}