import { Flex, Image, Text } from "@chakra-ui/react";

interface IBlockSwitchProps {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
  name: string;
  icon: string;
}

export const BlockSwitch = (props: IBlockSwitchProps) => {
  return (<>
    <Flex width="fit-content">
      <Flex
        border="1px solid #D0D5DD"
        borderRadius="12px"
        padding="16px"
        justifyContent="space-between"
        display="inline-flex"
        width="100%"
      >
        <Flex
          alignItems={'center'}
        >
          <Image 
            cursor="pointer"
            height="32px"
            width="32px"
            src={props.icon}
            resize="none"
            marginLeft="2px"
          />

          <Text
            fontSize="14px"
            fontFamily="Poppins-Medium"
            fontWeight="500"
            marginLeft="8px"
          >
            {props.name}
          </Text>
        </Flex>

        <Flex marginLeft="15px">
          <Flex
            display="flex"
            justifyContent="start"
            cursor="pointer"
            onClick={() => props.setIsActive(!props.isActive)}
            marginTop={'1.5%'}
          >
            { !props.isActive
              ? <Image w="50px" src="../icons/switch-gray.svg" />
              : <Image w="50px" src="../icons/switch-blue.svg" />
            }
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </>);
}
