import { CircularProgress, CircularProgressLabel, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { ICNDListData } from "../../../../_services/interface/cnd.interface"
import { ICardList } from "../../TaxKanban/interfaces/ICardList";
import { IWidgetIdent } from "../interfaces/IWidget";
import { calcPercent } from "../util/util";
import { WidgetBase } from "./WidgetBase"
import moment from 'moment';
import { IClientDataResponse } from "../../../../_services/interface/client.interface";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { ListAllCNdsCustomFilter } from "../../CNDManager/modals/listAllCNdsJurisdictionModal";
import { getCertificatesPropertyValuesRecursively } from "../../Certificate/Util/CertificateUtils";
import ReactPlaceholder from "react-placeholder/lib";
import { RectShape, RoundShape } from "react-placeholder/lib/placeholders";
moment.updateLocale('pt', {
  months: [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
    "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ]
});

interface ICndComplianceWidget extends IWidgetIdent {
  data?: ICNDListData[] | ICardList[];
  isLoading?: boolean;
  currentClient?: IClientDataResponse
  certificates?: ICertificate[];
  startDateFilter?: string;
  endDateFilter?: string;
}

export const CndComplianceWidget = (props: ICndComplianceWidget) => {
  const { isOpen: isOpenFederal, onOpen: onOpenFederal, onClose: onCloseFederal } = useDisclosure();
  const { isOpen: isOpenState, onOpen: onOpenState, onClose: onCloseState } = useDisclosure();
  const { isOpen: isOpenMunicipal, onOpen: onOpenMunicipal, onClose: onCloseMunicipal } = useDisclosure();
  
  const cndData = props.data ? props.data as ICNDListData[] : [];
  
  const products = props.currentClient?.products ? props.currentClient.products : [];

  const slugsAvailable: string[] = [];
  props.certificates?.forEach((certificate) => {
    if (certificate.slug && products[certificate.slug as string]) {
      const slugs = getCertificatesPropertyValuesRecursively([certificate], 'slug' as keyof ICertificate);
      slugs[0]?.forEach((slug: string) => {
        slugsAvailable.push(slug);
      });
    }
  }); 

  if (props.id && props.index !== undefined && props.currentClient && props.certificates) {
    const issuedData = cndData.filter((x) => x.slug_status === 'not-pendency' && x && slugsAvailable.includes(x.slug as string)); 
    const availableData = cndData.filter((x) => slugsAvailable.includes(x.slug as string)); 

    const availableDataEstate = availableData.filter((x) => x.jurisdiction === 'state');
    const availableDataFederal = availableData.filter((x) => x.jurisdiction === 'federal');
    const availableDataMunicipal = availableData.filter((x) => x.jurisdiction === 'municipal');

    const stateCalcTotal     = availableDataEstate.length;
    const federalCalcTotal   = availableDataFederal.length;
    const municipalCalcTotal = availableDataMunicipal.length;

    const stateCalc     = issuedData.filter((x) => x.jurisdiction === 'state').length;
    const federalCalc   = issuedData.filter((x) => x.jurisdiction === 'federal').length;
    const municipalCalc = issuedData.filter((x) => x.jurisdiction === 'municipal').length;

    const openFederal = () => {
      return () => {
        onOpenFederal();
      }
    }

    const openState = () => {
      return () => {
        onOpenState();
      }
    }

    const openMunicipal = () => {
      return () => {
        onOpenMunicipal();
      }
    }
    
    return (
      <WidgetBase title={`Gerenciador de Certidões - Certidões emitidas`} id={props.id} index={props.index}>
        <Flex flexDirection="column" gap={4} width="100%" alignItems="center" justifyContent="center">
          <Flex flexDirection="row" gap={4} width="100%" alignItems="center" justifyContent="center"> 

            <Flex cursor="pointer" flexDirection="column" onClick={openFederal()} justifyContent="center" gap={4}>
              <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1}>
                <Text textAlign="center">Federal</Text>
              </ReactPlaceholder>
              
              {
                props.isLoading ? (
                  <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
                ) : (
                  <CircularProgress value={calcPercent(federalCalc,federalCalcTotal)} color="#4b4eff" size='150px' thickness='10px'>
                    <CircularProgressLabel fontSize={14} fontWeight="bold">
                      {calcPercent(federalCalc,federalCalcTotal)}%<br/>Emitido
                    </CircularProgressLabel>
                  </CircularProgress>
                )
              }
            </Flex>
          
            <Flex flexDirection="column" cursor="pointer" onClick={openState()} justifyContent="center" gap={4}>
              <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1}>
                <Text textAlign="center">Estadual</Text>
              </ReactPlaceholder>

              {
                props.isLoading ? (
                  <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
                ) : (
                  <CircularProgress value={calcPercent(stateCalc,stateCalcTotal)} color="#4b4eff" size='150px' thickness='10px'>
                    <CircularProgressLabel fontSize={14} fontWeight="bold">
                      {calcPercent(stateCalc,stateCalcTotal)}%<br/>Emitido
                    </CircularProgressLabel>
                  </CircularProgress>
                )
              }
            </Flex>
            
            <Flex flexDirection="column" cursor="pointer" onClick={openMunicipal()} justifyContent="center" gap={4}>
              <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1}>
                <Text textAlign="center">Municipal</Text>
              </ReactPlaceholder>

              {
                props.isLoading ? (
                  <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
                ) : (
                  <CircularProgress value={calcPercent(municipalCalc,municipalCalcTotal)} color="#4b4eff" size='150px' thickness='10px'>
                    <CircularProgressLabel fontSize={14} fontWeight="bold">
                      {calcPercent(municipalCalc,municipalCalcTotal)}%<br/>Emitido
                    </CircularProgressLabel>
                  </CircularProgress> 
                )
              }
            </Flex>
          </Flex>
        </Flex>

        {props.certificates && props.currentClient ? (
          <>
            <ListAllCNdsCustomFilter 
              isOpen={isOpenFederal} 
              onClose={onCloseFederal} 
              jurisdiction="federal" 
              certificates={props.certificates} 
              currentClient={props.currentClient}
              data={availableDataFederal}
            />

            <ListAllCNdsCustomFilter
              isOpen={isOpenState}
              onClose={onCloseState}
              jurisdiction="state"
              certificates={props.certificates}
              currentClient={props.currentClient}
              startDateFilter={props.startDateFilter}
              endDateFilter={props.endDateFilter}
              data={availableDataEstate}
            />

            <ListAllCNdsCustomFilter
              isOpen={isOpenMunicipal}
              onClose={onCloseMunicipal}
              jurisdiction="municipal"
              certificates={props.certificates}
              currentClient={props.currentClient}
              startDateFilter={props.startDateFilter}
              endDateFilter={props.endDateFilter}
              data={availableDataMunicipal}
            />
          </>
        ) : null}
        
      </WidgetBase>
    )
  } else {
    return null;
  }
}